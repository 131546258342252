import { Banner } from "../../molecules";
import { Page } from "../../atoms";

const Blog = () => {
  return (
    <Page title="Blog">
      <Banner title="Blog" />
      <div id="dib-posts" />
    </Page>
  );
};

export default Blog;
