import axios from "axios";

export const sendContactForm = (token, formData, setShowSnackBar) => {
  axios
    .post(
      "https://api.bizknowledge.com/contactUs",
      { token, formData }
    )
    .then((response) => {
      setShowSnackBar({
        show: true,
        type: "success",
        message: "The information has been received",
      });
    })
    .catch((err) => {
      console.log(err)
      setShowSnackBar({
        show: true,
        type: "fail",
        message: "Unable to send. Please email team@bizknowledge.com directly.",
      });
    })
    .finally(() => {
      setTimeout(() => {
        setShowSnackBar({ show: false, type: "", message: "" });
      }, 5000);
    });
};
