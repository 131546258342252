import { useEffect } from "react";
import { styled } from "@linaria/atomic";
import { mediaQuery } from "../../theme";

const PageRoot = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-image: url("/src/assets/backgrounds/hero-background.webp");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;

  ${mediaQuery.desktop} {
    background-size: 110%;
  }
`;

const Page = ({ title, children }) => {
  useEffect(() => {
    document.title = `BizKnowledge - ${title}`;
    return () => {
      document.title = "BizKnowledge";
    };
  }, [title]);

  return <PageRoot>{children}</PageRoot>;
};

export default Page;
