import { useState } from "react";
import { colors, mediaQuery } from "../../../theme";
import { Article, Button, Paragraph, Section, TextHeader } from "../../atoms";
import { css } from "@linaria/core";
import { useNavigate } from "react-router-dom";

const StepItem = ({ id, label, content, selected, setSelected, step }) => {
  const isSelected = selected === step;

  return (
    <li
      key={id}
      style={{
        borderRadius: 9,
        border: `1px solid ${isSelected ? colors.secondary : "transparent"}`,
        padding: "24px",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        opacity: isSelected ? 1 : 0.5,
        cursor: "pointer",
      }}
      onClick={() => setSelected(step)}
    >
      <h2
        style={{
          fontSize: "1.5rem",
          fontWeight: 700,
          color: colors.secondary,
        }}
      >
        {label}
      </h2>
      <Paragraph>{content}</Paragraph>
    </li>
  );
};

//photos should not appear if viewing on mobile
export const Images = css`
  display: none;
  padding: 16px;
  align-items: center;

  ${mediaQuery.desktop} {
    display: flex;
  }
`;

const HowItWorks = ({ subheader, content, cta }) => {
  const [selected, setSelected] = useState(content[0]);
  const navigate = useNavigate();
  const navigation = cta?.external
    ? () => (window.location.href = cta.action)
    : () => navigate(cta.action);

  return (
    selected && (
      <Section>
        <div className={Images}>
          <img
            src={selected?.image}
            alt={selected?.imageAlt}
            style={{
              height: selected?.height ?? "auto",
              width: selected?.width ?? 600,
            }}
          />
        </div>
        <Article>
          <TextHeader>How It Works</TextHeader>
          <Paragraph align="center">{subheader}</Paragraph>
          <ol
            style={{
              marginTop: "1.5rem",
              listStyle: "none",
              margin: 0,
              padding: 0,
              display: "flex",
              flexDirection: "column",
              gap: "3rem",
            }}
          >
            {content.map((step) => (
              <StepItem
                key={step.id}
                id={step.id}
                label={step.label}
                content={step.content}
                image={step.image}
                step={step}
                selected={selected}
                setSelected={setSelected}
              />
            ))}
          </ol>
          <Button onClick={navigation}>{cta.label}</Button>
        </Article>
      </Section>
    )
  );
};

export default HowItWorks;
