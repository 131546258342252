import { styled } from "@linaria/atomic";
import { mediaQuery } from "../../theme";

const Section = styled.section`
  display: flex;
  flex-direction: ${(props) =>
    props.orientation === "right" ? "column-reverse" : "column"};
  align-items: center;
  padding: 64px 32px;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  transition: padding, 200ms;
  gap: 32px;

  ${mediaQuery.laptop} {
    flex-direction: row;
    gap: 64px;
  }

  ${mediaQuery.desktop} {
    padding: 128px;
    height: 100%;
  }
`;

export default Section;
