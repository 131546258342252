import { styled } from "@linaria/atomic";
import { css } from "@linaria/core";
import { colors, mediaQuery } from "../../../theme";

export const FooterRoot = styled.footer`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
  padding: 16px;

  ${mediaQuery.desktop} {
    padding: 16px 128px;
  }
`;

export const FooterTop = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  padding: 16px 0;
  border-bottom: 1px solid ${colors.divider};

  ${mediaQuery.desktop} {
    flex-direction: row;
  }
`;

export const FooterNav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  ${mediaQuery.desktop} {
    gap: 3rem;
    flex-direction: row;
  }
`;

export const SocialNav = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
`;

export const FooterLink = css`
  font-size: 1rem;
  color: ${colors.textSecondary};

  &:hover {
    color: ${colors.primary};
  }
`;

export const FooterText = css`
  font-size: 0.75rem;
  text-align: center;
  ${mediaQuery.desktop} {
    font-size: 0.85rem;
  }
`;

export const IconButton = styled.a`
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.15rem;

  &:hover {
    color: ${colors.primary};
    
    path {
      fill: ${colors.primary};
    }
  }
`;

export const XSvg = styled.svg`
  height: 18px;
  width: 18px;
`;
