import { styled } from "@linaria/atomic";
import { mediaQuery, colors } from "../../../theme";

export const SectionRoot = styled.header`
  height: 22vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 24px 0;

  ${mediaQuery.desktop} {
      height: 28vh;
      padding: 12px 0 0 0;
  }
`;

export const Title = styled.h1`
  font-size: 48px;
  font-weight: 700;
  text-align: center;
  color: ${colors.secondary};

  ${mediaQuery.desktop} {
    font-size: 48px;
  }
`;

export const Subtitle = styled.p`
  font-size: 16px;
  text-align: center;
  color: ${colors.textSecondary};

  ${mediaQuery.desktop} {
    font-size: 20px;
  }
`;

export const ComponentContainer = styled.div`
  padding: 0 2rem;

  ${mediaQuery.tablet} {
    width: 400px;
    padding: 0;
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  appearance: none;
  border: 0;
  padding: 20px 36px;
  font-family: Lato, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none !important;
  border-radius: 12px;
  color: ${colors.white};
  background-color: ${colors.textPrimary};
  cursor: pointer;
  transition: width 200ms, font-size 200ms, background-color 200ms;
  font-size: 28px;
  width: 100%;

  ${mediaQuery.desktop} {
    display: inline-flex;
    width: fit-content;
    padding: 20px 36px;
  }

  &:hover {
    background-color: #cbcbcb;
  }
`;
