import { LandingPage } from "../../templates";
import {
  BusinessManOnTablet,
  BusinessRewards,
  HandShakeMan,
  BusinessManOnLaptop,
  BusinessMeetingRaisingHands,
  BusinessWomanInYellow,
  DoctorOnTablet,
} from "../../../assets";
import { Button, Paragraph } from "../../atoms";
import css from "./business.module.css";
import {ROUTES} from "../../../router/routes";

const data = {
  hero: {
    customHero: (
      <header className={css.hero}>
        <img alt="business-man-on-laptop" src={BusinessManOnLaptop} />
        <section>
          <h1>Share Your Opinion, Unleash Your Knowledge</h1>
          <p>
            Introducing <span className={css.heroAccent}>BizKnowledge</span>.
          </p>
          <p>
            The online market research community fueled by powerful insight and
            expert knowledge.
          </p>
          <Button onClick={() => (window.location.href = ROUTES.external.registrationEntry)}>Learn More</Button>
        </section>
        <img alt="doctor-on-laptop" src={DoctorOnTablet} />
      </header>
    ),
  },
  firstSection: {
    header: "An Invitation to Join",
    content: (
      <>
        <Paragraph>
          Companies across the globe seek expert feedback on their products and
          services. This feedback informs their decisions, whether it’s
          launching new products or enhancing existing ones.
        </Paragraph>
        <Paragraph>
          You’re invited to join our community and share your expertise on a
          diverse range of topics spanning from various industries, sectors and
          subject matters. Whether it’s technology, business, health, or any
          other field, experts like you share insights, collaborate, and drive
          positive change.
        </Paragraph>
      </>
    ),
    image: HandShakeMan,
    imageAlt: "professional-ethnic-woman",
    orientation: "right",
    cta: {
      label: "Join Now",
      action: ROUTES.external.registrationEntry,
      external: true
    },
  },
  howItWorks: {
    subheader: "",
    content: [
      {
        id: "signUp",
        label: "Step 1: Sign Up",
        content:
          "It's easy. Create your account and earn points right away as you complete your profile.",
        image: BusinessManOnTablet,
        imageAlt: "signup",
        width: 600,
      },
      {
        id: "earn",
        label: "Step 2: Participate & Earn",
        content:
          "Share your expert opinions on topics of interest and in a variety of ways that work best for \n" +
          "you: online surveys, phone interviews, focus groups, in-home product testing and more.",
        image: BusinessMeetingRaisingHands,
        imageAlt: "earn",
        width: 600,
      },
      {
        id: "redeem",
        label: "Step 3: Get Rewards",
        content:
          "Select from a wide variety of rewards from popular companies like Amazon, Apple, Visa and much more!",
        image: BusinessRewards,
        alt: "rewards",
        width: 525,
        height: 400,
      },
    ],
    cta: {
      label: "Get Started",
      action: ROUTES.external.registrationEntry,
      external: true
    },
  },
  dataPrivacy: {
    header: "Our Commitment to Privacy",
    content: (
      <Paragraph>
        We prioritize your privacy and data security. Rest assured that your
        information will always remain confidential and will not be sold or
        shared with third parties without your consent.
      </Paragraph>
    ),
    image: BusinessWomanInYellow,
    imageAlt: "business-woman-in-yellow",
    orientation: "right",
  },
};
const Business = () => {
  return <LandingPage title="Business" data={data} />;
};

export default Business;
