import "../../assets/css/styles.css";
import BkBanner from "../../assets/backgrounds/bk-banner.webp";

const RegistrationError = () => {
  return (
    <div class="registration-error-root">
      <main className="registration-error-main">
        <section class="registration-error-header">
          <img src={BkBanner} alt="bk-banner" loading="eager" />
        </section>
        <section class="registration-error-content">
          <article className="article">
            <h1 class="h1">Sorry! Looks like something went wrong.</h1>
            <p class="p">Come back later.</p>
          </article>
        </section>
        <section class="registration-error-footer">
          By participating you acknowledge that you agree to BizKnowledge's{" "}
          <a href="https://www.bizknowledge.com/privacy-policy">
            Privacy Policy
          </a>{" "}
          and{" "}
          <a href="https://www.bizknowledge.com/terms-of-service">
            Terms of Use
          </a>
        </section>
      </main>
    </div>
  );
};

export default RegistrationError;
