import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { Header, Footer, Main } from "../molecules";

const Layout = () => {
  const ref = useRef();
  const location = useLocation();

  /* resets scroll position to top on path change */
  useEffect(() => {
    window.scrollTo(0, 0);
    window.onbeforeunload = () => {
      window.scrollTo(0, 0);
    };
  }, [location]);

  return (
    <div ref={ref}>
      <Header />
      <Main />
      <Footer />
    </div>
  );
};

export default Layout;
