import { RouterProvider } from "react-router-dom";
import { css } from "@linaria/core";
import { colors } from "./theme";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import router from "./router/router";

function App() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lf_9W0iAAAAAIeQtGsxkwtZfb-D898sV-4C-oYn">
      <RouterProvider router={router} />
    </GoogleReCaptchaProvider>
  );
}

export default App;

export const globals = css`
  :global() {
    :root {
      font-size: 16px;
    }

    html {
      font-family: "General Sans", sans-serif;
      font-size: 100%;
      block-size: 100%;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      text-rendering: geometricPrecision;
      -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
      text-size-adjust: 100%;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      box-sizing: border-box;
      overscroll-behavior: none;
    }

    *,
    *::before,
    *::after {
      box-sizing: inherit;
      margin: 0;
      padding: 0;
    }

    body {
      width: 100vw;
      height: 100vh;
      margin: 0;
      padding: 0;
      font-family: "Lato", sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    a {
      color: #545454;
      font-weight: 700;
      text-decoration: none;

      &:hover {
        color: ${colors.primary};
        text-decoration: underline;
      }
    }

    p {
      line-height: 1.5rem;
    }

    br {
      line-height: 1rem;
    }

    ul,
    ol {
      padding-left: 2rem;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    table {
      border-collapse: collapse;
      width: 100%;
      contain: size layout style paint;
      content-visibility: auto;
      border: 1px solid ${colors.border};
    }

    th {
      padding: 4px 16px;
      background-color: ${colors.divider};
      border: 1px solid ${colors.border};
    }

    td {
      padding: 4px 16px;
      border: 1px solid ${colors.border};
    }

    h1 {
      font-size: 1.5rem;
      font-weight: 900;
    }

    h2 {
      font-size: 1.25rem;
      font-weight: 900;
    }

    //Custom Drop-in-blog styles
    #dib-template-2 a.dib-post {
      flex: 16.67% !important;
      margin: 16px !important;
    }
  }
`;
