import { styled } from "@linaria/atomic";
import { mediaQuery, colors } from "../../theme";

const TextHeader = styled.h1`
  font-weight: 700;
  font-size: 2rem;
  color: ${colors.secondary};
  text-align: center;

  ${mediaQuery.tablet} {
    text-align: left;
  }

  ${mediaQuery.desktop} {
    font-size: 2.5rem;
  }
`;

export default TextHeader;
