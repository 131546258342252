import Banner from "../../molecules/Banner";
import { styled } from "@linaria/atomic";
import { mediaQuery } from "../../../theme";
import { Page } from "../../atoms";
import { ROUTES } from "../../../router/routes";
import { NavLink } from "react-router-dom";

const Main = styled.main`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 2rem 3rem;
  line-height: 1.5;
  ${mediaQuery.desktop} {
    padding: 2rem 12rem;
  }
`;

const Terms = () => {
  return (
    <Page title="Terms of Service">
      <Banner
        title="Terms of Service"
        subtitle="Last Updated: January 18, 2022"
      />
      <Main>
        <p>
          Please read these Terms of Service (the <strong>“Terms”</strong>) and
          our Privacy Policy (
          <NavLink to={ROUTES.internal.privacyPolicy}>
            https://bizknowledge.com/privacy-policy
          </NavLink>
          ) (<strong>“Privacy Policy”</strong>) carefully because they govern
          your use of the website located at{" "}
          <a href="https://www.bizknowledge.com" target="_blank" rel="noreferrer">
            www.bizknowledge.com
          </a>{" "}
          (the <strong>“Site”</strong>) and services accessible via the Site and
          corresponding mobile application (<strong>“App”</strong>) and software
          (<strong>“Software”</strong>) offered by Veridata Insights, LLC (
          <strong>“Veridata Insights”</strong>). To make these Terms easier to
          read, the Site, our services, Software and App are collectively called
          the <strong>“Services.”</strong>
          <br />
        </p>

        <p>
          <strong>
            IMPORTANT NOTICE REGARDING ARBITRATION FOR U.S. CUSTOMERS: WHEN YOU
            AGREE TO THESE TERMS YOU ARE AGREEING (WITH LIMITED EXCEPTION) TO
            RESOLVE ANY DISPUTE BETWEEN YOU AND VERIDATA INSIGHTS THROUGH
            BINDING, INDIVIDUAL ARBITRATION RATHER THAN IN COURT. PLEASE REVIEW
            CAREFULLY SECTION 17 “DISPUTE RESOLUTION” BELOW FOR DETAILS
            REGARDING ARBITRATION.
          </strong>
          <br />
        </p>
        <ol type="1">
          <li>
            <b>Our Services.</b> By using our Services, you agree to be bound by
            these Terms. If you don’t agree to be bound by these Terms, do not
            use the Services. If you elect to participate in our Services, you
            will have the opportunity to participate in market research
            activities. To receive surveys relevant to you, please accurately
            complete your profile, and our systems will select activities for
            you based upon the information you provide.
          </li>
          <li>
            <b>Privacy Policy.</b> Please review our Privacy Policy (
            <NavLink to={ROUTES.internal.privacyPolicy}>
              https://bizknowledge.com/privacy-policy
            </NavLink>
            ), which also governs your use of the Services, for information on
            how we collect, use, and share your information.
          </li>
          <li>
            <b>Changes to these Terms or the Services.</b> We may update the
            Terms from time to time in our sole discretion. If we do, we’ll let
            you know by posting the updated Terms on the Site, to the App and/or
            may also send other communications. If you continue to use the
            Services after we have posted updated Terms, it means that you
            accept and agree to the changes. If you don’t agree to be bound by
            the changes, you may not use the Services anymore. Because our
            Services are evolving over time we may change or discontinue all or
            any part of the Services, at any time and without notice, at our
            sole discretion.
          </li>
          <li>
            <b>Rewards.</b>
          </li>
          <ol type="a">
            <li>
              In connection with your use of the Services, you may have the
              opportunity to earn rewards, incentives, and entries into prize
              draws or sweepstakes. Your participation in survey, focus groups,
              telephone or other research as a part of the Services (the{" "}
              <strong>“Research”</strong>) is based on your desire to share
              opinions and provide feedback. Information, official rules, and
              terms and conditions for rewards, incentives, and prize draws or
              sweepstakes may be available on the Panel Website, at the
              beginning or end of a survey, in survey invitations, on the
              website(s) for redeeming rewards, incentives, and prizes, and/or
              may be described in any newsletter or other communication
              distributed or published by BizKnowledge, powered by Veridata
              Insights. Any consideration that is paid to you for participating
              in the Research is not calculated based on time spent by you.
              Likewise, any consideration that is paid to you for participation
              in the Research is not pro-rated on an hourly basis or otherwise.
              You are voluntarily participating in the Research, without control
              or direction of Veridata Insights and exercising independent
              judgement and discretion.
            </li>
            <li>
              You shall not auction, trade barter or sell any rewards,
              incentives and/or prizes you may receive for participating in the
              Services, and the rewards, incentives and/or prizes you may
              receive for participating in the Services are not transferrable
              upon death, by gift, as part of a domestic relations matter, or
              otherwise, except by operation of law. Unclaimed reward points
              will be forfeited and will not be refunded if you have not
              participated in the Research in 365 consecutive days.
            </li>
            <li>
              You must earn 10,000 rewards points before you are permitted to
              redeem your rewards. Once you meet this 10,0000-point threshold,
              you may redeem your points for rewards, including, without
              limitation, through gift cards. A valid and verified account is
              required for redemption of points. We reserve the right to verify
              the identity of payment service account holders and withhold
              payment in our sole discretion, if we believe that the identity of
              such account holder differs from the BizKnowledge, powered by
              Veridata Insights user; if we believe the user has violated this
              Agreement; or if we believe that the user has violated federal,
              state, or local law. We are not liable for the functionality of
              third parties that provide services through which a user may
              redeem points. Rewards, incentives, and prizes are
              non-transferrable.
            </li>
            <li>
              YOU HEREBY EXPRESSLY ACKNOWLEDGE AND AGREE THAT THE POINTS AND
              REWARDS YOU EARN THROUGH THE SERVICES MAY BE SUBJECT TO TAX, AND
              IT IS SOLELY YOUR RESPONSIBILITY TO PAY ALL SUCH TAXES. VERIDATA
              INSIGHTS SHALL NOT PAY SUCH TAXES ON YOUR BEHALF. We may provide
              you and/or the appropriate government agency or taxing authority
              with information related to any payments or incentives you earn in
              connection with your use of the Services. You agree to provide us
              with all required information to assist us in complying with our
              reporting or withholding obligations. We may withhold any tax from
              any incentive or reward as required by applicable law.
            </li>
          </ol>
          <li>
            <b>Who May Use the Services, and User Conduct.</b>
          </li>
          <ol type="a">
            <li>
              You may use the Services only if you are 18 years or older and
              capable of forming a binding contract with BizKnowledge, powered
              by Veridata Insights, and not otherwise barred from using the
              Services under applicable law. Employees of Veridata Insights are
              not permitted to participate in the Services or claim rewards.
            </li>
            <li>
              We rely on truthful and accurate data to serve our clients. When
              you register, you represent and warrant that you will provide us
              with truthful and accurate information about yourself, will not
              impersonate any person, and will update your information if it is
              no longer accurate. When you use the Services, you represent and
              warrant that you will truthfully and accurately answer survey
              questions. We reserve the right to withhold awarding any rewards
              to you if we, in our reasonable discretion, believe your responses
              are untruthful or not well considered. You may only open one
              account on our panel. Your account is not transferrable. Please do
              not disclose your password to anyone else, you are responsible for
              keeping it safe and for all activity that occurs under your
              profile. We will not be liable for any unauthorized use of your
              account.
            </li>
          </ol>
          <li>
            <b>Software License.</b>
          </li>
          <ol type="a">
            <li>
              From time to time, Veridata Insights may make Software available
              to you in connection with the Services. If you comply with these
              Terms, Client grants to you during the term of this Agreement a
              limited non-exclusive, non-transferable, license, with no right to
              sublicense, to download and install the Software on your personal
              computers, mobile handsets, tablets, wearable devices, and/or
              other devices and to run the Software solely in connection with
              your use of the Services.
            </li>
            <li>
              Except as expressly permitted in these Terms, you may not: (i)
              create derivative works based on the Software; (ii) distribute,
              transfer, sublicense, lease, lend or rent the Software to any
              third party; (iii) reverse engineer, decompile or disassemble the
              Software (unless applicable law permits, despite this limitation);
              or (iv) make the functionality of the Software available to
              multiple users through any means.
            </li>
          </ol>
          <li>
            <b>Our Intellectual Property.</b> We may make available through the
            Services content, (including text, sound, photographs, graphics,
            software and other materials, services, or Software) that owned by
            BizKnowledge, powered by Veridata Insights and/or its customers,
            licensors, and suppliers, and is subject to intellectual property
            rights. We and/or our customers, licensors, and suppliers retain all
            rights to that content. Except as may be permitted by applicable
            law, you shall not copy, modify, reproduce, republish, post,
            transmit, sell, offer for sale, or redistribute such content in any
            way without the express written consent of BizKnowledge, powered by
            Veridata Insights and/or its customers, licensors, and suppliers (as
            applicable). You must abide by all copyright notices, information,
            or restrictions contained in or affixed to any such content. Any and
            all survey responses, images, videos, responses to questions, data,
            and information you provide while participating in the Services,
            including as part of a survey or study (collectively,{" "}
            <strong>“Participant Data”</strong>), is the sole property of
            Veridata Insights. In consideration of the rewards you receive for
            participating in the Services, you hereby assign and shall assign to
            Veridata Insights all of your right, title and interest in and to
            the Participant Data to Veridata Insights.
          </li>
          <li>
            <b>Rights and Terms for Apps.</b> From time to time, we may make
            App(s) available to you. If we do, the terms of this Section 8 are
            applicable to the App(s):
          </li>
          <ol type="a">
            <li>
              <u>App License.</u> If you comply with these Terms, BizKnowledge,
              powered by Veridata Insights grants to you a limited
              non-exclusive, non-transferable license, with no right to
              sublicense, to download and install the App on your personal
              computers, mobile handsets, tablets, wearable devices, and/or
              other devices and to run the App solely for your own personal
              non-commercial purposes.
            </li>
            <li>
              Except as expressly permitted in these Terms, you may not: (i)
              copy, modify or create derivative works based on the App; (ii)
              distribute, transfer, sublicense, lease, lend or rent the App to
              any third party; (iii) reverse engineer, decompile or disassemble
              the App (unless applicable law permits, despite this limitation);
              or (iv) make the functionality of the App available to multiple
              users through any means.
            </li>
            <li>
              <u>Additional Information: Apple App Store.</u> This Section 6(c)
              applies to any App that you acquire from the Apple App Store or
              use on an iOS device. Apple has no obligation to furnish any
              maintenance and support services with respect to the App. In the
              event of any failure of the App to conform to any applicable
              warranty, you may notify Apple, and Apple will refund the App
              purchase price to you (if applicable) and, to the maximum extent
              permitted by applicable law, Apple will have no other warranty
              obligation whatsoever with respect to the App. Apple is not
              responsible for addressing any claims by you or any third party
              relating to the App or your possession and use of it, including,
              but not limited to: (i) product liability claims; (ii) any claim
              that the App fails to conform to any applicable legal or
              regulatory requirement; and (iii) claims arising under consumer
              protection or similar legislation. Apple is not responsible for
              the investigation, defense, settlement, and discharge of any
              third-party claim that your possession and use of the App infringe
              that third party's intellectual property rights. Apple and its
              subsidiaries are third-party beneficiaries of these Terms, and
              upon your acceptance of the Terms, Apple will have the right (and
              will be deemed to have accepted the right) to enforce these Terms
              against you as a third-party beneficiary thereof. You represent
              and warrant that (i) you are not located in a country that is
              subject to a U.S. Government embargo, or that has been designated
              by the U.S. Government as a terrorist-supporting country; and (ii)
              you are not listed on any U.S. Government list of prohibited or
              restricted parties. You must also comply with any applicable
              third-party terms of service when using the App.
            </li>
          </ol>
          <li>
            <b>
              General Prohibitions and Veridata Insights’ Enforcement Rights.
            </b>{" "}
            You agree not to do any of the following:
          </li>
          <ol type="a">
            <li>
              Use, display, mirror or frame the Services or any individual
              element within the Services, Veridata Insights’ name, any Veridata
              Insights trademark, logo or other proprietary information, or the
              layout and design of any page or form contained on a page, without
              Veridata Insights’ express written consent;
            </li>
            <li>
              Access, tamper with, or use non-public areas of the Services,
              Veridata Insights’ computer systems, or the technical delivery
              systems of Veridata Insights’ providers;
            </li>
            <li>
              Attempt to probe, scan or test the vulnerability of any Veridata
              Insights system or network or breach any security or
              authentication measures;
            </li>
            <li>
              Avoid, bypass, remove, deactivate, impair, descramble or otherwise
              circumvent any technological measure implemented by Veridata
              Insights or any of Veridata Insights’ providers or any other third
              party (including another user) to protect the Services;
            </li>
            <li>
              Attempt to access or search the Services or download content from
              the Services using any engine, software, tool, agent, device or
              mechanism (including spiders, robots, crawlers, data mining tools
              or the like) other than the software and/or search agents provided
              by Veridata Insights or other generally available third-party web
              browsers;
            </li>
            <li>
              Send any unsolicited or unauthorized advertising, promotional
              materials, email, junk mail, spam, chain letters or other form of
              solicitation;
            </li>
            <li>
              Use any meta tags or other hidden text or metadata utilizing a
              Veridata Insights trademark, logo URL or product name without
              Veridata Insights’ express written consent;
            </li>
            <li>
              Use the Services, or any portion thereof, for any commercial
              purpose or for the benefit of any third party or in any manner not
              permitted by these Terms;
            </li>
            <li>
              Forge any TCP/IP packet header or any part of the header
              information in any email or newsgroup posting, or in any way use
              the Services to send altered, deceptive or false
              source-identifying information;
            </li>
            <li>
              Attempt to decipher, decompile, disassemble or reverse engineer
              any of the software used to provide the Services;
            </li>
            <li>
              Interfere with, or attempt to interfere with, the access of any
              user, host or network, including, without limitation, sending a
              virus, overloading, flooding, spamming, or mail-bombing the
              Services;
            </li>
            <li>
              Collect or store any personally identifiable information from the
              Services from other users of the Services without their express
              permission;
            </li>
            <li>
              Impersonate or misrepresent your affiliation with any person or
              entity;
            </li>
            <li>Violate any applicable law or regulation; or</li>
            <li>
              Encourage or enable any other individual to do any of the
              foregoing.
            </li>
          </ol>
          <br />
          BizKnowledge, powered by Veridata Insights is not obligated to monitor
          access to or use of the Services or to review or edit any content.
          However, we have the right to do so for the purpose of operating the
          Services, to ensure compliance with these Terms and to comply with
          applicable law or other legal requirements. We reserve the right, but
          are not obligated, to remove or disable access to any content at any
          time and without notice, including, but not limited to, if we, at our
          sole discretion, consider it objectionable or in violation of these
          Terms. We have the right to investigate violations of these Terms or
          conduct that affects the Services. We may also consult and cooperate
          with law enforcement authorities to prosecute users who violate the
          law.
          <br />
          <br />
          <li>
            <b>Links to Third Party Websites or Resources.</b> The Services
            (including the App) may allow you to access third-party websites or
            other resources. We provide access only as a convenience and are not
            responsible for the content, products or services on or available
            from those resources or links displayed on such websites. You
            acknowledge sole responsibility for and assume all risk arising
            from, your use of any third-party resources.
          </li>
          <li>
            <b>Confidentiality.</b> When you participate in a marketing research
            panel, you may be exposed to new products and innovative ideas from
            our clients, and you may not share any of the information relating
            to the products, ideas or research activity in which you
            participated in any medium. If Veridata Insights has reason to
            believe you shared material containing our clients’ confidential
            information, trade secrets or other intellectual property outside
            the context of the research activity, we may immediately terminate
            your access to and use of the Services.
          </li>
          <li>
            <b>Termination.</b>
          </li>
          <ol type="a">
            <li>
              We may suspend or terminate your access to and use of the
              Services, including suspending access to or terminating your
              account, at our sole discretion, at any time and without notice to
              you. If we terminate your access to and use of the Services due to
              your violation of these Terms: (i) you forfeit all rights, title
              and interest in and/or to all unredeemed rewards, incentives,
              and/or prizes, effective upon termination; (ii) your access to,
              participation in, and use of the Services will immediately cease;
              and (iii) you will not be allowed to participate in surveys
              offered through the Services.
            </li>
            <li>
              You may cancel your account at any time by either: (i) sending us
              an email at{" "}
              <a href="mailto:team@bizknowledge.com">team@bizknowledge.com</a>;
              or (ii) logging into your account, going to “Account Settings,”
              and selecting the option to cancel your account. If you terminate
              your account for any reason, you forfeit any rewards, incentives
              and/or prizes in your account.
            </li>
            <li>
              Upon any termination, discontinuation or cancellation of the
              Services or your account, the following Sections will survive:
              4(b), 4(c), 5, 6(b), 7, 8(b), 8(c) and 9-18.
            </li>
          </ol>
          <li>
            <b>Warranty Disclaimers.</b> THE SERVICES ARE PROVIDED “AS IS,”
            WITHOUT WARRANTY OF ANY KIND. FOR CLARITY, NEITHER VERIDATA INSIGHTS
            NOT ITS VENDORS OR THIRD-PARTY PARTNERS MAKE ANY WARRANTY, EXPRESS
            OR IMPLIED: (A) WITH REGARD TO ANY PRODUCTS, SERVICES OR GIFTS
            OBTAINED BY YOU THROUGH THE PANEL; (B) ANY RESULTS YOU MAY OBTAIN BY
            USING THE PANEL; (C) WITHOUT LIMITING THE FOREGOING, WE EXPLICITLY
            DISCLAIM ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, QUIET ENJOYMENT AND NON-INFRINGEMENT, AND ANY
            WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. We
            make no warranty that the Services will meet your requirements or be
            available on an uninterrupted, secure, or error-free basis. We make
            no warranty regarding the quality, accuracy, timeliness,
            truthfulness, completeness or reliability of any information or
            content on the Services. No advice or information, whether oral or
            written, obtained by you from Veridata Insights or via the panel
            shall create any warranty not expressly made herein. Veridata
            Insights cannot and shall not be liable or responsible for any
            guarantees, warranties and/or representations, if any, offered by
            clients, partners, reward partners, manufacturers or merchandise or
            suppliers of services.
          </li>
          <li>
            <b>Indemnity.</b> You will indemnify and hold Veridata Insights and
            its officers, directors, employees and agents, harmless from and
            against any claims, disputes, demands, liabilities, damages, losses,
            and costs and expenses, including, without limitation, reasonable
            legal and accounting fees arising out of or in any way connected
            with (a) your access to or use of the Services, or (b) your
            violation of these Terms.
          </li>
          <li>
            <b>Limitation of Liability.</b>
          </li>
          <ol type="a">
            <li>
              TO THE MAXIMUM EXTENT PERMITTED BY LAW, NEITHER VERIDATA INSIGHTS
              NOR ITS OFFICERS, DIRECTORS, STOCKHOLDERS, SUBSIDIARIES,
              EMPLOYEES, AGENTS, LICENSORS, AFFILIATES OR SERVICE PROVIDERS
              INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SERVICES WILL
              BE LIABLE FOR ANY DAMAGES OR LOSSES, INCLUDNG ANY INCIDENTAL,
              SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, OR DAMAGES FOR
              INABILITY TO USE THE PANEL, ANY TRANSACTION ENTERED INTO OR
              THROUGH THE PANEL, THE RELIANCE ON OR USE OF ANY INFORMATION,
              GOODS, SERVICES OR MERCHANDISE PROVIDED ON THE PANEL, UNAUTHORIZED
              ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA, STATEMENTS
              OR CONDUCT OF ANY THIRD PARTY, ANY CLAIM ATTRIBUTABLE TO ERRORS,
              OMISSIONS OR OTHER INACCURACIES IN THE PANEL AND/OR MATERIALS OR
              INFORMAITON RETRIEVED THROUGH THE PANEL, LIABILITY ARISING FROM
              FRAUD, DEATH OR PERSONAL INJURY ARISING FROM NEGLIGENCE, LOST
              PROFITS, LOST REVENUES, LOST SAVINGS, LOST BUSINESS OPPORTUNITY,
              LOSS OF GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM
              FAILURE OR THE COST OF SUBSTITUTE SERVICES OF ANY KIND ARISING OUT
              OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR
              INABILITY TO USE THE SERVICES, AND ANY OTHER MATTER RELATING TO
              THE PANELS OR THESE TERMS WHETHER BASED ON WARRANTY, CONTRACT,
              TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL
              THEORY, AND WHETHER OR NOT VERIDATA INSIGHTS OR ITS SERVICE
              PROVIDERS HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE,
              EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED
              OF ITS ESSENTIAL PURPOSE.
            </li>
            <li>
              TO THE MAXIMUM EXTENT PERMITTED BY THE LAW OF THE APPLICABLE
              JURISDICTION, IN NO EVENT WILL VERIDATA INSIGHTS’ TOTAL LIABILITY
              ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE
              OF OR INABILITY TO USE THE SERVICES EXCEED THE AMOUNTS YOU HAVE
              PAID OR ARE PAYABLE BY YOU TO VERIDATA INSIGHTS FOR USE OF THE
              SERVICES OR ONE HUNDRED DOLLARS ($100), IF YOU HAVE NOT HAD ANY
              PAYMENT OBLIGATIONS TO VERIDATA INSIGHTS, AS APPLICABLE.
            </li>
            <li>
              THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE
              FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN VERIDATA
              INSIGHTS AND YOU.
            </li>
          </ol>
          <li>
            <b>Governing Law and Forum Choice.</b>. These Terms and any action
            related thereto will be governed by the Federal Arbitration Act,
            federal arbitration law, and the laws of the State of Texas, without
            regard to its conflict of laws provisions. Except as otherwise
            expressly set forth in Section 17 “Dispute Resolution,” the
            exclusive jurisdiction for all Disputes, (defined in Section 17
            below), that you and Veridata Insights are not required to arbitrate
            will be the state and federal courts located in Collin County,
            Texas, and you and Veridata Insights each waive any objection to
            jurisdiction and venue in such courts.
          </li>
          <li>
            <b>Dispute Resolution.</b>
          </li>
          <ol type="a">
            <li>
              <u>Mandatory Arbitration of Disputes.</u> We each agree that any
              dispute, claim, or controversy arising out of or relating to these
              Terms or the breach, termination, enforcement, interpretation, or
              validity thereof or the use of the Services (collectively,{" "}
              <strong>“Disputes”</strong>) will be resolved{" "}
              <strong>
                solely by binding, individual arbitration and not in a class,
                representative or consolidated action or proceeding
              </strong>
              . You and Veridata Insights agree that the U.S. Federal
              Arbitration Act governs the interpretation and enforcement of
              these Terms, and that you and Veridata Insights are each waiving
              the right to a trial by jury or to participate in a class action.
              This arbitration provision shall survive termination of these
              Terms.
            </li>
            <li>
              <u>Exceptions.</u> As limited exceptions to Section 17(a) above:
              (i) we both may seek to resolve a Dispute in small claims court if
              it qualifies; and (ii) we each retain the right to seek injunctive
              or other equitable relief from a court to prevent (or enjoin) the
              infringement or misappropriation of our intellectual property
              rights.
            </li>
            <li>
              <u>Conducting Arbitration and Arbitration Rules.</u> The
              arbitration will be conducted by the American Arbitration
              Association (<strong>“AAA”</strong>) under its Consumer
              Arbitration Rules (the <strong>“AAA Rules”</strong>) then in
              effect, except as modified by these Terms. The AAA Rules are
              available at{" "}
              <a href="https://www.adr.org" target="_blank" rel="noreferrer">
                www.adr.org
              </a>{" "}
              or by calling 1-800-778-7879. A party who wishes to start
              arbitration must submit a written Demand for Arbitration to AAA
              and give notice to the other party as specified in the AAA Rules.
              The AAA provides a form Demand for Arbitration at{" "}
              <a href="https://www.adr.org" target="_blank" rel="noreferrer">
                www.adr.org
              </a>
              .<br />
              Any arbitration hearings will take place in the county (or parish)
              where you live, unless we both agree to a different location. The
              parties agree that the arbitrator shall have exclusive authority
              to decide all issues relating to the interpretation,
              applicability, enforceability and scope of this arbitration
              agreement.
            </li>
            <li>
              <u>Arbitration Costs.</u> Payment of all filing, administration
              and arbitrator fees will be governed by the AAA Rules, and we will
              not seek to recover the administration and arbitrator fees we are
              responsible for paying, unless the arbitrator finds your Dispute
              frivolous. If we prevail in arbitration, we will pay all of our
              attorneys’ fees and costs and will not seek to recover them from
              you. If you prevail in arbitration, you will be entitled to an
              award of attorneys’ fees and expenses to the extent provided under
              applicable law.
            </li>
            <li>
              <u>Injunctive and Declaratory Relief.</u> Except as provided in
              Section 17(b) above, the arbitrator shall determine all issues of
              liability on the merits of any claim asserted by either party and
              may award declaratory or injunctive relief only in favor of the
              individual party seeking relief and only to the extent necessary
              to provide relief warranted by that party’s individual claim. To
              the extent that you or we prevail on a claim and seek public
              injunctive relief (that is, injunctive relief that has the primary
              purpose and effect of prohibiting unlawful acts that threaten
              future injury to the public), the entitlement to and extent of
              such relief must be litigated in a civil court of competent
              jurisdiction and not in arbitration. The parties agree that
              litigation of any issues of public injunctive relief shall be
              stayed pending the outcome of the merits of any individual claims
              in arbitration.
            </li>
            <li>
              <u>Class Action Waiver.</u>{" "}
              <strong>
                YOU AND VERIDATA INSIGHTS AGREE THAT EACH MAY BRING CLAIMS
                AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND
                NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
                REPRESENTATIVE PROCEEDING.
              </strong>{" "}
              Further, if the parties’ Dispute is resolved through arbitration,
              the arbitrator may not consolidate another person’s claims with
              your claims and may not otherwise preside over any form of a
              representative or class proceeding. If this specific provision is
              found to be unenforceable, then the entirety of this Dispute
              Resolution section shall be null and void.
            </li>
            <li>
              <u>Severability.</u> With the exception of any of the provisions
              in Section 17(f) of these Terms (
              <strong>“Class Action Waiver”</strong>), if an arbitrator or court
              of competent jurisdiction decides that any part of these Terms is
              invalid or unenforceable, the other parts of these Terms will
              still apply.
            </li>
          </ol>
          <li>
            <b>General Terms.</b>
          </li>
          <ol type="a">
            <li>
              <u>Reservation of Rights.</u> Veridata Insights and its licensors
              exclusively own all right, title and interest in and to the
              Services, including all associated intellectual property rights.
              You acknowledge that the Services are protected by copyright,
              trademark, and other laws of the United States and foreign
              countries. You agree not to remove, alter, or obscure any
              copyright, trademark, service mark or other proprietary rights
              notices incorporated in or accompanying the Services.
            </li>
            <li>
              <u>Entire Agreement.</u> These Terms constitute the entire and
              exclusive understanding and agreement between Veridata Insights
              and you regarding the Services, and these Terms supersede and
              replace all prior oral or written understandings or agreements
              between Veridata Insights and you regarding the Services. If any
              provision of these Terms is held invalid or unenforceable by an
              arbitrator or a court of competent jurisdiction, that provision
              will be enforced to the maximum extent permissible, and the other
              provisions of these Terms will remain in full force and effect.
              You may not assign or transfer these Terms, by operation of law or
              otherwise, without Veridata Insights’ prior written consent. Any
              attempt by you to assign or transfer these Terms, without such
              consent, will be null. Veridata Insights may freely assign or
              transfer these Terms without restriction. Subject to the
              foregoing, these Terms will bind and inure to the benefit of the
              parties, their successors and permitted assigns.
            </li>
            <li>
              <u>Notices.</u> Any notices or other communications provided by
              Veridata Insights under these Terms will be given: (i) via email;
              or (ii) by posting to the Services. For notices made by email, the
              date of receipt will be deemed the date on which such notice is
              transmitted.
            </li>
            <li>
              <u>Waiver of Rights.</u> Veridata Insights’ failure to enforce any
              right or provision of these Terms will not be considered a waiver
              of such right or provision. The waiver of any such right or
              provision will be effective only if in writing and signed by a
              duly authorized representative of Veridata Insights. Except as
              expressly set forth in these Terms, the exercise by either party
              of any of its remedies under these Terms will be without prejudice
              to its other remedies under these Terms or otherwise.
            </li>
          </ol>
          <li>
            <b>Contact Information.</b>If you have any questions about these
            Terms or the Services, please contact Veridata Insights at:
          </li>
        </ol>
        <p>
          BizKnowledge, powered by Veridata Insights, LLC
          <br />
          Jami Pulley, CEO and Founder
          <br />
          101C North Greenville Ave, Suite 317
          <br />
          Allen, TX 75002
          <br />
          (888) 517-8783
          <br />
          <a
            href="mailto:compliance@bizknowledge.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#0645AD" }}
          >
            compliance@bizknowledge.com
          </a>
        </p>
      </Main>
    </Page>
  );
};

export default Terms;
