import { styled } from "@linaria/atomic";

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 0 auto;
`;

const PageNotFound = () => {
  return (
    <Container>
      Sorry! The page you are looking for doesn't exist.
      <a href="/">Back to Home Page</a>
    </Container>
  );
};

export default PageNotFound;
