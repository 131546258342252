import { styled } from "@linaria/atomic";
import { mediaQuery } from "../../theme";

const Article = styled.article`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;

  ${mediaQuery.desktop} {
    width: 50%;
  }
`;

export default Article;
