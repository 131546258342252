import { useCallback, useState } from "react";
import { css } from "@linaria/core";
import Banner from "../../molecules/Banner";
import { mediaQuery, colors } from "../../../theme";
import { styled } from "@linaria/atomic";
import { Page } from "../../atoms";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { sendContactForm } from "../../../utils/contact-util";
import Snackbar from "../../molecules/SnackBar/SnackBar";

const Section = styled.section`
  display: flex;
  align-items: center;
  padding: 32px;
  width: 100%;
  justify-content: center;
  transition: padding, 200ms;

  ${mediaQuery.desktop} {
    padding: 0 400px 128px 400px;
  }
`;

const Input = styled.input`
  font-family: Lato, sans-serif;
  border-radius: 6px;
  border: 1px solid ${colors.border};
  padding: 12px 16px;
  resize: none;
  font-size: 1rem;
`;

const Select = styled.select`
  font-family: Lato, sans-serif;
  border-radius: 6px;
  border: 1px solid ${colors.border};
  padding: 12px 16px;
  resize: none;
  font-size: 1rem;
`;

const TextAreaInput = styled.textarea`
  font-family: Lato, sans-serif;
  border: 1px solid ${colors.border};
  border-radius: 6px;
  padding: 12px 16px;
  resize: none;
  font-size: 1rem;
`;

const Button = styled.button`
  color: ${colors.white};
  font-family: Lato, sans-serif;
  background-color: ${colors.primary};
  padding: 20px 32px;
  cursor: pointer;
  border: 0.1rem solid;
  border-radius: 12px;
  font-size: 1.25rem;
  font-weight: 700;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 0.25px;
  transition: background-color 200ms;

  &:hover {
    background-color: ${colors.secondary};
  }
`;

const formWrapper = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const Contact = () => {
  const [token, setToken] = useState();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [input, setInput] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  }); // include all input names
  const [showSnackBar, setShowSnackBar] = useState({
    show: false,
    type: "",
    message: "",
  }); // include all input names

  const onVerify = useCallback(
    (token) => {
      setToken(token);
    },
    [refreshReCaptcha]
  );

  const handleChange = (e, type) =>
    setInput({
      ...input,
      [type]: e.target.value,
    });

  const handleSubmit = (event) => {
    event.preventDefault();
    setRefreshReCaptcha((r) => !r);
    sendContactForm(token, input, setShowSnackBar);
    setInput({ name: "", email: "", subject: "", message: "" });
  };

  const handleClose = () => {
    setShowSnackBar({ show: false, type: "", message: "" });
  };

  return (
    <>
      <Page title="Contact">
        <Banner
          title="Contact Us"
          subtitle="Need additional help? Send us a message."
        />
        <Section>
          <form onSubmit={handleSubmit} className={formWrapper}>
            <Input
              id="name"
              type="text"
              required
              placeholder="Your Name"
              value={input.name}
              onChange={(e) => handleChange(e, "name")}
            />
            <Input
              id="email"
              type="email"
              required
              placeholder="Email"
              value={input.email}
              onChange={(e) => handleChange(e, "email")}
            />
            <Select
              id="subject"
              required
              value={input.subject}
              onChange={(e) => handleChange(e, "subject")}
            >
              <option value="" defaultValue="" disabled hidden>
                Subject
              </option>
              <option value="account">Account</option>
              <option value="registration">Registration</option>
              <option value="rewards">Rewards</option>
              <option value="survey">Survey</option>
              <option value="Unsubscribe">Unsubscribe</option>
              <option value="website">Website</option>
              <option value="other">Other</option>
            </Select>
            <TextAreaInput
              id="message"
              required
              rows={10}
              placeholder="Message"
              value={input.message}
              onChange={(e) => handleChange(e, "message")}
            />
            <Button type="submit">Send Message</Button>
          </form>
          <GoogleReCaptcha
            onVerify={onVerify}
            refreshReCaptcha={refreshReCaptcha}
          />
        </Section>
      </Page>
      <Snackbar
        setShowSnackBar={setShowSnackBar}
        showSnackBar={showSnackBar}
        handleClose={handleClose}
      />
    </>
  );
};

export default Contact;
