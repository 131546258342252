import { NavLink } from "react-router-dom";
import { Stack } from "../../atoms";
import { HeaderRoot, Link, Links, Logo, SignInButton } from "./Header.styles";
import { ROUTES } from "../../../router/routes";
import { LogoHeader } from "../../../assets";

const HEADER_LINKS = [
  { id: "faqs", label: "FAQs", href: ROUTES.internal.faqs },
  { id: "blog", label: "Blog", forceRefresh: true, href: ROUTES.internal.blog },
  { id: "contact", label: "Contact", href: ROUTES.internal.contact },
];

const Header = () => {
  return (
    <HeaderRoot>
      <NavLink to="/">
        <Logo src={LogoHeader} alt="BizKnowledge Logo" />
      </NavLink>
      <Stack direction="row" spacing={1}>
        <ul className={Links}>
          {HEADER_LINKS.map((link) =>
            link.forceRefresh ? (
              <a key={link.id} href={link.href} className={Link}>
                {link.label}
              </a>
            ) : (
              <li key={link.id}>
                <NavLink to={link.href} className={Link}>
                  {link.label}
                </NavLink>
              </li>
            )
          )}
        </ul>
        <button
          className={SignInButton}
          onClick={() => (window.location.href = ROUTES.external.login)}
        >
          Sign In
        </button>
      </Stack>
    </HeaderRoot>
  );
};

export default Header;
