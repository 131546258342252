const colors = {
  primary: "#8BC3FE", //logo blue
  secondary: "#6289b3", //header blue - 30% darker version from color comparison doc (see Notion)
  textPrimary: "#181818",
  textSecondary: "#33333D",
  divider: "#f2f2f2",
  border: "#dcdcdc",
  white: "#FFFFFF",
  black: "#000000",
  action: {
    hover: "#f2f2f2",
  },
};

export default colors;
