import { Button, Stack } from "../../atoms";
import {
  ComponentContainer,
  SectionRoot,
  Subtitle,
  Title,
} from "./Banner.styles";

const Banner = ({ title, subtitle, cta, component }) => {
  return (
    <SectionRoot>
      <Stack direction="column" spacing={1} align="center">
        <Title>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </Stack>
      {cta && <Button>{cta}</Button>}
      <ComponentContainer>{component}</ComponentContainer>
    </SectionRoot>
  );
};

export default Banner;
