import { NavLink } from "react-router-dom";
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import {
  FooterLink,
  FooterNav,
  FooterRoot,
  FooterText,
  FooterTop,
  IconButton,
  SocialNav,
  XSvg,
} from "./Footer.styles";
import { ROUTES } from "../../../router/routes";
import { getCurrentYear } from "../../../utils/data-util";

const FOOTER_LINKS = [
  {
    id: "faqs",
    label: "FAQS",
    to: ROUTES.internal.faqs,
  },
  {
    id: "blog",
    label: "Blog",
    forceRefresh: true,
    to: ROUTES.internal.blog,
  },
  {
    id: "contact",
    label: "Contact",
    to: ROUTES.internal.contact,
  },
  {
    id: "terms",
    label: "Terms of Service",
    to: ROUTES.internal.termsOfService,
  },
  {
    id: "privacy",
    label: "Privacy Policy",
    to: ROUTES.internal.privacyPolicy,
  },
];

const SOCIAL_LINKS = [
  {
    id: "linkedIn",
    url: ROUTES.external.linkedIn,
    icon: <FaLinkedinIn />,
  },
  {
    id: "facebook",
    url: ROUTES.external.facebook,
    icon: <FaFacebookF />,
  },
  {
    id: "twitter",
    url: ROUTES.external.twitter,
    icon: (
      <XSvg
        width="1100"
        height="1227"
        viewBox="0 0 1200 1227"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
          fill="#33333D"
        />
      </XSvg>
    ),
  },
  {
    id: "instagram",
    url: ROUTES.external.instagram,
    icon: <FaInstagram />,
  },
];

const Footer = () => {
  const year = getCurrentYear();
  return (
    <FooterRoot>
      <FooterTop>
        <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
          <FooterNav>
            {FOOTER_LINKS.map((link) =>
              link.forceRefresh ? (
                <a key={link.id} href={link.to} className={FooterLink}>
                  {link.label}
                </a>
              ) : (
                <NavLink key={link.id} to={link.to} className={FooterLink}>
                  {link.label}
                </NavLink>
              )
            )}
          </FooterNav>
        </div>
        <SocialNav>
          {SOCIAL_LINKS.map((social) => (
            <IconButton
              key={social.id}
              href={social.url}
              alt={social.id}
              target="_blank"
            >
              {social.icon}
            </IconButton>
          ))}
        </SocialNav>
      </FooterTop>
      <p className={FooterText}>
        © {year}{" "}
        <a href={ROUTES.external.veridata} target="_blank" rel="noreferrer">
          Veridata Insights.
        </a>{" "}
        All rights reserved.
      </p>
    </FooterRoot>
  );
};

export default Footer;
