import { Article, Button, Section, TextHeader } from "../atoms";
import { css } from "@linaria/core";
import { mediaQuery } from "../../theme";
import { useNavigate } from "react-router-dom";

const Image = css`
  border-radius: 50%;
  width: 70%;
  height: auto;

  ${mediaQuery.tablet} {
    width: 40%;
  }
`;

const Block = ({ header, content, cta, image, imageAlt, orientation }) => {
  const navigate = useNavigate();
    const navigation = cta?.external ? () => (window.location.href = cta.action) : () => navigate(cta.action)
    return (
    <Section orientation={orientation}>
      {orientation === "left" && (
        <img alt={imageAlt} src={image} className={Image} />
      )}
      <Article>
        <TextHeader variant="h1">{header}</TextHeader>
        {content}
        {cta && (
          <Button onClick={navigation}>
            {cta.label}
          </Button>
        )}
      </Article>
      {orientation === "right" && (
        <img alt={imageAlt} src={image} className={Image} />
      )}
    </Section>
  );
};

export default Block;
