import Banner from "../../molecules/Banner";
import { styled } from "@linaria/atomic";
import { mediaQuery } from "../../../theme";
import { Page } from "../../atoms";
import { css } from "@linaria/core";
import { ROUTES } from "../../../router/routes";

const Main = styled.main`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 2rem 3rem;
  line-height: 1.5;
  ${mediaQuery.desktop} {
    padding: 2rem 12rem;
  }
`;

const Link = css`
  color: #0645ad;
`;

const Privacy = () => {
  return (
    <Page title="Privacy Policy">
      <Banner
        title="Privacy Policy"
        subtitle="Last Updated: January 26, 2022"
      />
      <Main>
        <p>
          Veridata Insights (“<strong>Veridata Insights</strong>,” “
          <strong>we</strong>,” or “<strong>us</strong>”), offers a market
          research service, called BizKnowledge (“
          <strong>BizKnowledge</strong>”), that allows our customers to collect
          permission based information and data. This Privacy Policy is designed
          to help BizKnowledge panel members and website viewers (collectively,
          “<strong>you</strong>”) understand how we collect, use, and share your
          personal information and to help you understand and exercise your
          privacy rights.
        </p>
        <ol type="1">
          <li>SCOPE</li>
          <li>PERSONAL INFORMATION WE COLLECT</li>
          <li>HOW WE USE YOUR INFORMATION</li>
          <li>HOW WE DISCLOSE YOUR INFORMATION</li>
          <li>YOUR PRIVACY CHOICES AND RIGHTS</li>
          <li>SECURITY OF YOUR INFORMATION</li>
          <li>INTERNATIONAL DATA TRANSFERS</li>
          <li>RETENTION OF PERSONAL INFORMATION</li>
          <li>SUPPLEMENTAL NOTICE FOR CALIFORNIA RESIDENTS</li>
          <li>SUPPLEMENTAL NOTICE FOR NEVADA RESIDENTS</li>
          <li>CHILDREN’S INFORMATION</li>
          <li>OTHER PROVISIONS</li>
          <li>CONTACT US</li>
        </ol>

        <h2>1. SCOPE</h2>
        <p>
          This Privacy Policy applies to personal information processed by us,
          including on our websites, mobile applications we may make available,
          and other online or offline offerings. To make this Privacy Policy
          easier to read, our websites, mobile applications, and other offerings
          are collectively called “<strong>Services</strong>.”
        </p>
        <p>
          <strong>
            <u>An Important Note:</u>
          </strong>{" "}
          This Privacy Policy does not apply to any of the personal information
          that our customers may process using Veridata Insights’ products and
          services (“<strong>Customer Data</strong>”). Our customers’ respective
          privacy policies govern the collection and use of Customer Data. Our
          processing of Customer Data is governed by the contracts that we have
          in place with our customers, not this Privacy Policy. Any questions or
          requests relating to Customer Data should be directed to our customer.
        </p>

        <h2>2. PERSONAL INFORMATION WE COLLECT</h2>

        <p>
          The categories of personal information we collect depend on how you
          interact with us, our Services and the requirements of applicable law.
          We collect information that you provide to us, information we obtain
          automatically when you use our Services, and information from other
          sources such as third-party services and organizations, as described
          below.
        </p>

        <ol type="A">
          <li>
            <strong>Information You Provide to Us Directly</strong>
            <br />
            <p>
              We may collect the following personal information that you provide
              to us.
            </p>
            <ul style={{ listStyleType: "circle" }}>
              <li>
                <strong>Registration. </strong> If you register to potentially
                become a panel member, we may collect information when you
                register, such as first name, last name, date of birth, gender,
                email address, street address, city, zip code and telephone
                number. We also collect additional data you voluntarily include
                in your member profile.
                <br />
                <p>
                  <i>
                    <u>
                      We may also collect Sensitive Personal Information if you
                      volunteer to provide it and consent to our collection of
                      it. “Sensitive Personal Information” means personal
                      information that discloses or reveals political opinions,
                      religious or philosophical beliefs, sexual orientation or
                      sexual life, race or ethnic origin, trade union
                      membership, genetic information, data concerning health
                      and/pr medical conditions including disability status.
                      Please see “How We Use Sensitive Information” below for
                      more information about how we use Sensitive Personal
                      Information.
                    </u>
                  </i>
                </p>
              </li>
              <li>
                <strong>Panel Participation. </strong> We collect personal
                information from you if you participate in a panel, including
                without limitation when you download and/or complete a survey,
                compete an inquiry form, participate in a contest, and/or in
                connection with the receipt and redemption of rewards and
                incentives. Information may be collected in various ways,
                including through inquiry forms, surveys and contests. The
                categories of personal information we collect may include, for
                example: name, address, phone number, email address, data of
                birth, social media information, race and gender information.
                <br />
                <p>
                  <i>
                    <u>
                      We may also collect Sensitive Personal Information if you
                      volunteer to provide it and consent to our collection of
                      it. Please see “How We Use Sensitive Information” below
                      for more information about how we use Sensitive Personal
                      Information. You may refuse to answer certain questions or
                      discontinue participation in a panel at any time.
                    </u>
                  </i>
                </p>
              </li>
              <li>
                <strong>Purchases. </strong> We may collect personal information
                and details associated with your purchases, including payment
                information. Any payments made via our Services are processed by
                third-party payment processors. We do not directly collect or
                store any payment card information entered through our Services,
                but it may receive information associated with your payment card
                information (e.g., your billing details).
              </li>
              <li>
                <strong>Your Communications with Us. </strong> We may collect
                personal information, such as email address, phone number, or
                mailing address when you request information about our Services,
                register for our newsletter or research program(s), request
                customer or technical support, apply for a job or otherwise
                communicate with us.
              </li>
              <li>
                <strong>Interactive Features. </strong> We and others who use
                our Services may collect personal information that you submit or
                make available through our interactive features (e.g., messaging
                and chat features, commenting functionalities, forums, blogs,
                and social media pages). Any information you provide on the
                public sections of these features will be considered “public”
                and is not subject to the privacy protections referenced herein.
              </li>
              <li>
                <strong>Sweepstakes or Contests. </strong> We may collect
                personal information you provide for any sweepstakes or contests
                that we offer. In some jurisdictions, we are required to
                publicly share information of sweepstakes and contest winners.
              </li>
              <li>
                <strong>
                  Business Development and Strategic Partnerships.{" "}
                </strong>{" "}
                We may collect personal information from individuals and third
                parties to assess and pursue potential business opportunities.
              </li>
              <li>
                <strong>Job Applications. </strong> We may post job openings and
                opportunities on our Services. If you reply to one of these
                postings by submitting your application, CV and/or cover letter
                to us, we will collect and use your information to assess your
                qualifications.
              </li>
            </ul>
            <br />
          </li>
          <li>
            <strong>Information Collected Automatically</strong>
            <br />
            <p>
              We may collect personal information automatically when you use our
              Services:
            </p>
            <ul style={{ listStyleType: "circle" }}>
              <li>
                <strong>Automatic Data Collection.</strong> We may collect
                certain information automatically when you use our Services,
                such as your Internet protocol (IP) address, user settings, log
                files, digital fingerprinting, watermarking, browsing activity,
                MAC address, cookie identifiers, mobile carrier, network
                provider user ID (a number uniquely allocated to you by your
                network provider), mobile advertising and other unique
                identifiers, browser or device information, device operating
                system, information about your device (e.g., device operating
                system, the other applications on your device, device type, time
                zone, network status, browser type, browser identifier and other
                information that alone or in combination may be used to uniquely
                identify your device), location information (including
                approximate location derived from IP address), Internet service
                provider and other behavioral information. We may also
                automatically collect information regarding your use of our
                Services, such as pages that you visit before, during and after
                using our Services, information about the links you click, the
                types of content you interact with, the frequency and duration
                of your activities, and other information about how you use our
                Services. <strong>Location Information.</strong> We may collect
                precise location information, such as through GPS, RFID, WiFi
                and cellular triangulation. You will receive prior notice and
                will be asked to consent before your geolocation information is
                collected. If you do not wish to or no longer wish to share your
                geo-location data through your device, please change the privacy
                settings in your device for the Services.
              </li>
              <li>
                <strong>
                  Cookies, Pixel Tags/Web Beacons, and Other Technologies.
                </strong>{" "}
                We, as well as third parties that provide content, advertising,
                or other functionality on our Services, may use cookies, pixel
                tags, local storage, and other technologies (“Technologies”) to
                automatically collect information through your use of our
                Services.
                <br />
                <ul style={{ listStyleType: "circle" }}>
                  <li>
                    <strong>Cookies.</strong> Cookies are small text files
                    placed in device browsers that store preferences and
                    facilitate and enhance your experience.
                  </li>
                  <li>
                    <strong>Pixel Tags/Web Beacons.</strong> A pixel tag (also
                    known as a web beacon) is a piece of code embedded in our
                    Services that collects information about engagement on our
                    Services. The use of a pixel tag allows us to record, for
                    example, that a user has visited a particular web page or
                    clicked on a particular advertisement. We may also include
                    web beacons in e-mails to understand whether messages have
                    been opened, acted on, or forwarded.
                    <br />
                    <p>
                      Our uses of these Technologies fall into the following
                      general categories:
                    </p>
                  </li>
                  <li>
                    <strong>Operationally Necessary.</strong> This includes
                    Technologies that allow you access to our Services,
                    applications, and tools that are required to identify
                    irregular website behavior, prevent fraudulent activity and
                    improve security or that allow you to make use of our
                    functionality;
                  </li>
                  <li>
                    <strong>Performance-Related.</strong> We may use
                    Technologies to assess the performance of our Services,
                    including as part of our analytic practices to help us
                    understand how individuals use our Services{" "}
                    <i>(see Analytics below)</i>;
                  </li>
                  <li>
                    <strong>Functionality-Related.</strong> We may use
                    Technologies that allow us to offer you enhanced
                    functionality when accessing or using our Services. This may
                    include identifying you when you sign into our Services or
                    keeping track of your specified preferences, interests, or
                    past items viewed;
                  </li>
                  <li>
                    <strong>Advertising- or Targeting-Related.</strong> We may
                    use first party or third-party Technologies to deliver
                    content, including ads relevant to your interests, on our
                    Services or on third-party websites.
                    <br />
                    <p>
                      <i>
                        See Section 5 below to understand your choices regarding
                        these Technologies.
                      </i>
                    </p>
                  </li>
                </ul>
                <br />
              </li>
              <li>
                <strong>Analytics.</strong> We may use Technologies and other
                third-party tools to process analytics information on our
                Services. Some of our analytics partners include: <br />
                <ul style={{ listStyleType: "circle" }}>
                  <li>
                    <strong>Google Analytics.</strong> For more information,
                    please visit{" "}
                    <a
                      href="https://policies.google.com/technologies/partner-sites"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={Link}
                    >
                      Google Analytics’ Privacy Policy
                    </a>
                    . To learn more about how to opt-out of Google Analytics’
                    use of your information, please click{" "}
                    <a
                      href="https://tools.google.com/dlpage/gaoptout"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={Link}
                    >
                      here
                    </a>
                    .
                  </li>
                  <li>
                    <strong>LinkedIn Analytics.</strong> For more information,
                    please visit{" "}
                    <a
                      href="https://www.linkedin.com/legal/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={Link}
                    >
                      LinkedIn Analytics’ Privacy Policy
                    </a>
                    . To learn more about how to opt-out of LinkedIn’s use of
                    your information, please click{" "}
                    <a
                      href="https://www.linkedin.com/help/linkedin/answer/62931?trk=microsites-frontend_legal_privacy-policy&lang=en"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={Link}
                    >
                      here
                    </a>
                    .
                  </li>
                </ul>
              </li>
              <li>
                <strong>Social Media Platforms.</strong> Our Services may
                contain social media buttons such as Facebook Survey
                Opportunities (that might include widgets such as the “share
                this” button or other interactive mini programs). These features
                may collect your IP address, which page you are visiting on our
                Services, and may set a cookie to enable the feature to function
                properly. Your interactions with these platforms are governed by
                the privacy policy of the company providing it.
              </li>
            </ul>
          </li>
          <li>
            <strong>Information Collected from Other Sources</strong>
            <br />
            <p>
              We may obtain information about you from other sources, including
              through third-party services and organizations. For example, if
              you access our Services through a third-party application, such as
              an app store, a third-party login service, or a social networking
              site, we may collect information about you from that third-party
              application that you have made available via your privacy
              settings.
            </p>
          </li>
        </ol>

        <h2>
          3. HOW WE USE YOUR INFORMATION
          <a id="infouse" />
        </h2>
        <p>
          We use your information for a variety of business purposes, including
          to provide our Services, for administrative purposes, and to market
          our products and Services, as described below.
        </p>

        <ol type="A">
          <li>
            <strong>Provide Our Services</strong>
            <br />
            <p>
              We may use your information to fulfil our contract with you and
              provide you with our Services, such as:
            </p>
            <ul style={{ listStyleType: "circle" }}>
              <li>Managing your information and accounts;</li>
              <li>Confirm your identity during the registration process;</li>
              <li>
                Providing access to certain areas, functionalities, and features
                of our Services;
              </li>
              <li>Answering requests for customer or technical support;</li>
              <li>
                Communicating with you about your account, survey opportunities,
                surveys you participated in, and policy changes;
              </li>
              <li>To market our products/services to you;</li>
              <li>
                To enable us or third parties to develop marketing insights
                and/or audience/look alike models;
                <br />
                Allowing our payment processors to process your financial
                information and other payment methods for products or Services
                purchased or payment of rewards earned;
              </li>
              <li>
                Processing applications if you apply for a job, we post on our
                Services; To provide you with survey opportunities specific to
                your profile;
              </li>
              <li>
                To link your device and/or profile to additional information
                available about you and/or your device available from data
                management platforms;
              </li>
              <li>
                Arrange for advertisements to be displayed to you outside the
                Application/Services for the purposes of facilitating the
                completion of advertisement recall surveys;
              </li>
              <li>To send you survey notifications;</li>
              <li>
                To administer and manager your incentives via the BizKnowledge
                program and fulfill your requests for rewards and incentives;
                and
              </li>
              <li>
                To facilitate your entry into a sweepstakes and promotions.
              </li>
            </ul>
            <br />
          </li>
          <li>
            <strong>Administrative Purposes</strong>
            <br />
            <p>
              We use your information for various administrative purposes, such
              as:
            </p>
            <ul style={{ listStyleType: "circle" }}>
              <li>
                Pursuing our legitimate interests such as marketing research,
                direct marketing, research and development, network and
                information security;
              </li>
              <li>
                To enable third parties to market their products/services to
                you;
              </li>
              <li>
                Detecting security incidents, protecting against malicious,
                deceptive, fraudulent or illegal activity, and prosecuting those
                responsible for that activity;
              </li>
              <li>Measuring interest and engagement in our Services;</li>
              <li>Updating our records;</li>
              <li>
                To comply with any data suppression obligations or requirements;
              </li>
              <li>
                Short-term, transient use, such as contextual customization of
                ads for marketing research purposes, (i.e. ad testing, ad
                recall);
              </li>
              <li>Improving, upgrading or enhancing our Services;</li>
              <li>Developing new products and Services;</li>
              <li>Ensuring internal quality control and safety;</li>
              <li>Authenticating and verifying individual identities;</li>
              <li>
                Debugging to identify and repair errors with our Services;
              </li>
              <li>
                Auditing relating to interactions, transactions and other
                compliance activities;
              </li>
              <li>Enforcing our agreements and policies; and</li>
              <li>
                Complying with our legal obligations, including tax obligations.
              </li>
            </ul>
            <br />
          </li>
          <li>
            <strong>Marketing and Advertising our Products and Services</strong>
            <p>
              We may use personal information to tailor and provide you with
              surveys, content and advertisements. We may provide you with these
              materials as permitted by applicable law.
            </p>
            <p>
              Some of the ways we market to you include email campaigns, custom
              audiences advertising, and “interest-based” or “personalized
              advertising,” including through cross-device tracking.
            </p>
            <p>
              <u>Ad partners' use of information.</u>
            </p>
            <p>
              You can use the Services to participate in surveys, including,
              without limitation, surveys regarding specific ads and promotions
              that we are testing for our business clients. You may see these
              ads or promotions in several places, including, without
              limitation, in non-Veridata Services mobile apps, after which we
              shall provide you with access to, or contact you to complete, a
              survey about the ad or promotion that you were exposed to. To
              facilitate the completion of surveys on ads or promotions, we have
              partnered with ad companies that provide, measure or facilitate
              advertisements, including, without limitation, in many other
              mobile apps. The Services provides these ad networks with the UID,
              your unique device identifier, and many or all of the other types
              of information described in the{" "}
              <u>“Information collected through automated means”</u> section
              above (including your ongoing geolocation, if your device permits
              the collection of geo-location). Then, when you use a mobile app
              that has partnered with one of the ad companies, the ad company
              can use your unique device identifier or other automatically
              collected information to recognize your device. The ad company may
              then show you an ad or promotion that we are testing for one of
              our business clients. The ad company may then inform us that the
              device associated with your UID has just received an ad or
              promotion, which allows us to provide you with access to, or to
              contact you to complete, a survey regarding the ad or promotion.
              With your consent, the ad companies also use the information they
              collect about your device and your interactions with the Services
              and other mobile apps to provide you with other in-app advertising
              that has been tailored to your interests and for other purposes.
              To learn more about some of these ad companies, including how to
              opt out, please visit the following:
            </p>
            <p>
              <a
                href="https://www.voicefive.com/preferences.aspx"
                target="_blank"
                rel="noopener noreferrer"
                className={Link}
              >
                https://www.voicefive.com/preferences.aspx
              </a>
            </p>
            <p>
              <a
                href="https://www.insightexpressai.com/adserver/optout.aspx"
                target="_blank"
                rel="noopener noreferrer"
                className={Link}
              >
                https://www.insightexpressai.com/adserver/optout.aspx
              </a>
            </p>
            <p>
              <a
                href="https://n01d01.cumulus-cloud.com/ssi/optout.php"
                target="_blank"
                rel="noopener noreferrer"
                className={Link}
              >
                https://n01d01.cumulus-cloud.com/ssi/optout.php
              </a>
            </p>
            <p>
              <a
                href="https://priv-policy.imrworldwide.com/priv/browser/us/en/optout.html"
                target="_blank"
                rel="noopener noreferrer"
                className={Link}
              >
                https://priv-policy.imrworldwide.com/priv/browser/us/en/optout.html
              </a>
            </p>
            <p>
              If you have any questions about our marketing practices or if you
              would like to opt out of the use of your personal information for
              marketing purposes, you may contact us at any time as set forth
              below.
            </p>
          </li>
          <li>
            <strong>Retargeting</strong> <br />
            <p>
              We may from time-to-time engage a third party or third parties to
              either display advertising on our website(s) or to manage our
              advertising on other websites (for example on social media sites).
              Veridata Insights’ third party partner(s) may use technologies
              such as cookies or identifying data elements, including but not
              limited to your IDFA or Advertising ID to gather information about
              your activities on our website(s) and/or on other websites to: (i)
              provide you with advertising about us and our services; and/or
              (ii) assist us in developing marketing and advertising campaigns
              designed to target individuals who have a social and demographic
              profile similar to your social and demographic profile. If
              Veridata Insights is conducting a re-targeting program and you
              wish to not participate in this re-targeting program, please
              opt-out via the applicable cookie opt-out process set forth in
              this Privacy Policy , by modifying your IDFA or Advertising ID
              and/or by unsubscribing within the advertisement itself
            </p>
          </li>
          <li>
            <strong>Use of Sensitive Personal Information</strong> <br />
            <p>
              We use Sensitive Personal Information to target survey
              opportunities to you.
            </p>
          </li>
          <li>
            <strong>Other Purposes</strong> <br />
            <p>
              We also use your information for other purposes as requested by
              you or as permitted by applicable law.
            </p>
            <ul style={{ listStyleType: "circle" }}>
              <li>
                <strong>Consent.</strong> We may use personal information for
                other purposes that are clearly disclosed to you at the time you
                provide personal information or with your consent.
              </li>
              <li>
                <strong>Automated Decision Making.</strong> We may engage in
                automated decision making, including profiling. Our processing
                of your personal information will not result in a decision based
                solely on automated processing that significantly affects you
                unless such a decision is necessary as part of a contract we
                have with you, we have your consent, or we are permitted by law
                to engage in such automated decision making. If you have
                questions about our automated decision making, you may contact
                us as set forth below.
              </li>
              <li>
                <strong>De-identified and Aggregated Information.</strong> We
                may use personal information and other information about you to
                create de-identified and/or aggregated information, such as
                de-identified demographic information, de-identified location
                information, information about the device from which you access
                our Services, or other analyses we create.
              </li>
              <li>
                <strong>Share Content with Friends or Colleagues.</strong> Our
                Services may offer various tools and functionalities. For
                example, we may allow you to provide information about your
                friends through our referral services. Our referral services may
                allow you to forward or share certain content with a friend or
                colleague, such as an email inviting your friend to use our
                Services.
              </li>
            </ul>
          </li>
        </ol>

        <h2>
          4. HOW WE DISCLOSE YOUR INFORMATION
          <a id="infodisclose" />
        </h2>
        <p>
          We disclose your information to third parties for a variety of
          business purposes, including to provide our Services, to protect us or
          others, or in the event of a major business transaction such as a
          merger, sale, or asset transfer, as described below.
        </p>

        <ol type="A">
          <li>
            <strong>Disclosures to Provide our Services</strong>
            <br /> The categories of third parties with whom we may share your
            information are described below.
            <ul style={{ listStyleType: "circle" }}>
              <li>
                <strong>Service Providers.</strong> We may share your personal
                information with our third-party service providers who use that
                information to help us provide our Services. This includes
                service providers that provide us with IT support, hosting,
                payment processing, customer service, data processing or appends
                and related services.
              </li>
              <ul style={{ listStyleType: "circle" }}>
                <li>
                  <strong>Imperium.</strong> We currently use Imperium to
                  provide authentication services for our customers. It is
                  important for us to verify your identity to curb fraudulent
                  activity and so our customers can be sure it is really you who
                  took their survey. We provide the following personal
                  information to Imperium: first name, last name, street
                  address, city, state, postal code, country, date of birth, IP
                  address, email address and phone number. Imperium uses your
                  personal information in perpetuity to confirm your identity,
                  and to enhance the precision of their services and develop
                  fraud detection products available for use by third parties.
                  By participating in a panel, you expressly consent to us
                  transferring your personal information to Imperium for the
                  purpose of identity authentication and so that Imperium can
                  enhance the precision of their services and develop fraud
                  detection products available for use by third parties. Please
                  carefully review Imperium’s privacy notice located at{" "}
                  <a
                    href="https://www.imperium.com/privacy/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={Link}
                  >
                    https://www.imperium.com/privacy/
                  </a>{" "}
                  for a complete description of their data practices prior to
                  participating in a panel and consenting to this use of your
                  personal information. If you do not want Imperium to use your
                  personal information for these purposes, please do not
                  participate in a panel.
                </li>
              </ul>
              <li>
                <strong>Business Partners.</strong> We may share your personal
                information with business partners with whom we jointly offer
                products or services.
              </li>
              <li>
                <strong>Affiliates.</strong> We may share your personal
                information with our company affiliates.
              </li>
              <li>
                <strong>Advertising Partners.</strong> We may share your
                personal information with third-party advertising partners.
                These third-party advertising partners may set Technologies and
                other tracking tools on our Services to collect information
                regarding your activities and your device (e.g., your IP
                address, cookie identifiers, page(s) visited, location, time of
                day). These advertising partners may use this information (and
                similar information collected from other services) for purposes
                of delivering personalized advertisements to you when you visit
                digital properties within their networks. This practice is
                commonly referred to as “interest-based advertising” or
                “personalized advertising.”
              </li>
              <li>
                <strong>APIs/SDKs.</strong> We may use third-party Application
                Program Interfaces (“APIs”) and software development kits
                (“SDKs”) as part of the functionality of our Services. For more
                information about our use of APIs and SDKs, please contact us as
                set forth below.
              </li>
            </ul>
          </li>
          <li>
            <strong>Disclosures to Protect Us or Others</strong>
            <br /> We may access, preserve, and disclose any information we
            store associated with you to external parties if we, in good faith,
            believe doing so is required or appropriate to: comply with law
            enforcement or national security requests and legal process, such as
            a court order or subpoena; protect your, our, or others’ rights,
            property, or safety; enforce our policies or contracts; collect
            amounts owed to us; or assist with an investigation or prosecution
            of suspected or actual illegal activity.
          </li>
          <li>
            <strong>
              Disclosure in the Event of Merger, Sale, or Other Asset Transfers
            </strong>
            <br /> If we are involved in a merger, acquisition, financing due
            diligence, reorganization, bankruptcy, receivership, purchase or
            sale of assets, or transition of service to another provider, your
            information may be sold or transferred as part of such a
            transaction, as permitted by law and/or contract.
          </li>
        </ol>

        <h2>
          5. YOUR PRIVACY CHOICES AND RIGHTS
          <a id="privacychoices" />
        </h2>
        <p>
          <strong>Your Privacy Choices.</strong> The privacy choices you may
          have about your personal information are determined by applicable law
          and are described below.
          <br />
        </p>
        <ul style={{ listStyleType: "circle" }}>
          <li>
            <strong>Email and Telephone Communications.</strong> If you receive
            an unwanted email from us, you can use the unsubscribe link found at
            the bottom of the email to opt out of receiving future emails. Note
            that you will continue to receive transaction-related emails
            regarding products or Services you have requested. We may also send
            you certain non-promotional communications regarding us and our
            Services, and you will not be able to opt out of those
            communications (e.g., communications regarding our Services or
            updates to our Terms or this Privacy Policy).
            <br />
            We process requests to be placed on do-not-mail, do-not-phone, and
            do-not-contact lists as required by applicable law.
          </li>
          <li>
            <strong>Text Messages.</strong> You may opt out of receiving text
            messages from us by [following the instructions in the text
            message/replying "STOP" to a text message you have received from us]
            or by otherwise contacting us.
          </li>
          <li>
            <strong>Mobile Devices.</strong> We may send you push notifications
            through our mobile application. You may opt out from receiving these
            push notifications by changing the settings on your mobile device.
            With your consent, we may also collect precise location-based
            information if you use our mobile application. You may opt out of
            this collection by changing the settings on your mobile device.
          </li>
          <li>
            <strong>“Do Not Track.”</strong> Do Not Track (“DNT”) is a privacy
            preference that users can set in certain web browsers. Please note
            that we do not respond to or honor DNT signals or similar mechanisms
            transmitted by web browsers.
          </li>
          <li>
            <strong>Cookies and Interest-Based Advertising.</strong> You may
            stop or restrict the placement of Technologies on your device or
            remove them by adjusting your preferences as your browser or device
            permits. However, if you adjust your preferences, our Services may
            not work properly. Please note that cookie-based opt-outs are not
            effective on mobile applications. However, you may opt-out of
            personalized advertisements on some mobile applications by following
            the instructions for{" "}
            <a
              href="https://support.google.com/googleplay/android-developer/answer/6048248?hl=en"
              target="_blank"
              rel="noopener noreferrer"
              className={Link}
            >
              Android
            </a>
            ,{" "}
            <a
              href="https://support.apple.com/en-us/HT202074"
              target="_blank"
              rel="noopener noreferrer"
              className={Link}
            >
              iOS
            </a>{" "}
            and{" "}
            <a
              href="https://thenai.org/opt-out/mobile-opt-out/"
              target="_blank"
              rel="noopener noreferrer"
              className={Link}
            >
              others
            </a>
            .<br />
            The online advertising industry also provides websites from which
            you may opt out of receiving targeted ads from data partners and
            other advertising partners that participate in self-regulatory
            programs. You can access these and learn more about targeted
            advertising and consumer choice and privacy by visiting the{" "}
            <a
              href="https://thenai.org/opt-out/"
              target="_blank"
              rel="noopener noreferrer"
              className={Link}
            >
              Network Advertising Initiative
            </a>
            ,{" "}
            <a
              href="https://optout.aboutads.info/?c=2&lang=EN"
              target="_blank"
              rel="noopener noreferrer"
              className={Link}
            >
              the Digital Advertising Alliance
            </a>
            ,{" "}
            <a
              href="https://www.youronlinechoices.eu/"
              target="_blank"
              rel="noopener noreferrer"
              className={Link}
            >
              the European Digital Advertising Alliance
            </a>
            , and{" "}
            <a
              href="https://youradchoices.ca/en/tools"
              target="_blank"
              rel="noopener noreferrer"
              className={Link}
            >
              the Digital Advertising Alliance of Canada
            </a>
            .<br />
            Please note you must separately opt out in each browser and on each
            device.
          </li>
        </ul>
        <p>
          <strong>Your Privacy Rights.</strong> In accordance with applicable
          law, you may have the right to:
          <br />
        </p>
        <ul style={{ listStyleType: "circle" }}>
          <li>
            <strong>Access Personal Information</strong> about you, including:
            (i) confirming whether we are processing your personal information;
            (ii) obtaining access to or a copy of your personal information; and
            (iii) receiving an electronic copy of personal information that you
            have provided to us, or asking us to send that information to
            another company (the “right of data portability”);
          </li>
          <li>
            <strong>Request Correction</strong> of your personal information
            where it is inaccurate or incomplete. In some cases, we may provide
            self-service tools that enable you to update your personal
            information;
          </li>
          <li>
            <strong>Request Deletion</strong> of your personal information;
          </li>
          <li>
            <strong>Request Restriction of or Object to</strong> our processing
            of your personal information; and<sup>1</sup>
          </li>
          <li>
            <strong>Withdraw your Consent</strong> Consent to our processing of
            your personal information. If you would like to exercise any of
            these rights, please contact us as set forth below.
            <br />
            We will process such requests in accordance with applicable laws
          </li>
        </ul>

        <h2>
          6. SECURITY OF YOUR INFORMATION
          <a id="infosec" />
        </h2>
        <p>
          We take steps to ensure that your information is treated securely and
          in accordance with this Privacy Policy. Unfortunately, no system is
          100% secure, and we cannot ensure or warrant the security of any
          information you provide to us. We have taken appropriate safeguards to
          require that your personal information will remain protected and
          require our third-party service providers and partners to have
          appropriate safeguards as well. To the fullest extent permitted by
          applicable law, we do not accept liability for unauthorized
          disclosure.
        </p>
        <p>
          By using our Services or providing personal information to us, you
          agree that we may communicate with you electronically regarding
          security, privacy, and administrative issues relating to your use of
          our Services. If we learn of a security system’s breach, we may
          attempt to notify you electronically by posting a notice on our
          Services, by mail or by sending an email to you.
        </p>

        <h2>
          7. INTERNATIONAL DATA TRANSFERS
          <a id="intdatatrans" />
        </h2>
        <p>
          All information processed by us may be transferred, processed, and
          stored anywhere in the world, including, but not limited to, the
          United States or other countries, which may have data protection laws
          that are different from the laws where you live. We endeavor to
          safeguard your information consistent with the requirements of
          applicable laws.
        </p>

        <h2>
          8. RETENTION OF PERSONAL INFORMATION <a id="piretention" />
        </h2>
        <p>
          We store the personal information we collect as described in this
          Privacy Policy for as long as you use our Services or as necessary to
          fulfill the purpose(s) for which it was collected, provide our
          Services, resolve disputes, establish legal defenses, conduct audits,
          pursue legitimate business purposes, enforce our agreements, and
          comply with applicable laws.
        </p>

        <h2>
          9. SUPPLEMENTAL NOTICE FOR CALIFORNIA RESIDENTS
          <a id="calres" />
        </h2>
        <p>
          This Supplemental California Privacy Notice only applies to our
          processing of personal information that is subject to the California
          Consumer Privacy Act of 2018 (“CCPA”). The CCPA provides California
          residents with the right to know what categories of personal
          information Veridata Insights has collected about them and whether
          Veridata Insights disclosed that personal information for a business
          purpose (e.g., to a service provider) in the preceding 12 months.
          California residents can find this information below:
        </p>

        <table>
          <tbody>
            <tr>
              <th>
                Category of Personal Information Collected by Veridata Insights
              </th>
              <th>
                Category of Third Parties Information is Disclosed to for a
                Business Purpose
              </th>
            </tr>
            <tr>
              <td>
                <strong>Identifiers.</strong> <br />A real name, postal address,
                unique personal identifier, online identifier, Internet Protocol
                address, email address, Social Security number, or other similar
                identifiers.
              </td>
              <td>
                <ul style={{ listStyleType: "circle" }}>
                  <li>Advertising networks</li>
                  <li>Internet service providers</li>
                  <li>Data analytics providers</li>
                  <li>Government entities</li>
                  <li>Operating systems and platforms</li>
                  <li>Social networks</li>
                  <li>Consumer data resellers</li>
                  <li>Service providers</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <strong>
                  Personal information categories listed in the California
                  Customer Records statute (Cal. Civ. Code § 1798.80(e))
                </strong>{" "}
                <br />A name, signature, Social Security number, physical
                characteristics or description, address, telephone number,
                education, employment, employment history, medical information,
                or health insurance information. Personal information does not
                include publicly available information that is lawfully made
                available to the general public from federal, state, or local
                government records. Note: Some personal information included in
                this category may overlap with other categories.
              </td>
              <td>
                <ul style={{ listStyleType: "circle" }}>
                  <li>Advertising networks</li>
                  <li>Internet service providers</li>
                  <li>Data analytics providers</li>
                  <li>Government entities</li>
                  <li>Operating systems and platforms</li>
                  <li>Social networks</li>
                  <li>Consumer data resellers</li>
                  <li>Service providers</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <strong>
                  Protected classification characteristics under California or
                  federal law
                </strong>{" "}
                <br />
                Age (40 years or older), race, color, ancestry, national origin,
                citizenship, religion or creed, marital status, medical
                condition, physical or mental disability, sex (including gender,
                gender identity, gender expression, pregnancy or childbirth and
                related medical conditions), sexual orientation, veteran or
                military status, genetic information (including familial genetic
                information).
              </td>
              <td>
                <ul style={{ listStyleType: "circle" }}>
                  <li>Advertising networks</li>
                  <li>Internet service providers</li>
                  <li>Data analytics providers</li>
                  <li>Government entities</li>
                  <li>Operating systems and platforms</li>
                  <li>Social networks</li>
                  <li>Consumer data resellers</li>
                  <li>Service providers</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Commercial information</strong> <br />
                Records of personal property, products or services purchased,
                obtained, or considered, or other purchasing or consuming
                histories or tendencies.
              </td>
              <td>
                <ul style={{ listStyleType: "circle" }}>
                  <li>Advertising networks</li>
                  <li>Internet service providers</li>
                  <li>Data analytics providers</li>
                  <li>Government entities</li>
                  <li>Operating systems and platforms</li>
                  <li>Social networks</li>
                  <li>Consumer data resellers</li>
                  <li>Service providers</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Internet or other electronic network activity</strong>{" "}
                <br />
                Browsing history, search history, information on a consumer's
                interaction with an internet website, application, or
                advertisement.
              </td>
              <td>
                <ul style={{ listStyleType: "circle" }}>
                  <li>Advertising networks</li>
                  <li>Internet service providers</li>
                  <li>Data analytics providers</li>
                  <li>Government entities</li>
                  <li>Operating systems and platforms</li>
                  <li>Social networks</li>
                  <li>Consumer data resellers</li>
                  <li>Service providers</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Geolocation data</strong> <br />
                Physical location or movements.
              </td>
              <td>
                <ul style={{ listStyleType: "circle" }}>
                  <li>Advertising networks</li>
                  <li>Internet service providers</li>
                  <li>Data analytics providers</li>
                  <li>Government entities</li>
                  <li>Operating systems and platforms</li>
                  <li>Social networks</li>
                  <li>Consumer data resellers</li>
                  <li>Service providers</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Sensory data</strong> <br />
                Audio, electronic, visual, thermal, olfactory, or similar
                information.
              </td>
              <td>
                <ul style={{ listStyleType: "circle" }}>
                  <li>Advertising networks</li>
                  <li>Internet service providers</li>
                  <li>Data analytics providers</li>
                  <li>Government entities</li>
                  <li>Operating systems and platforms</li>
                  <li>Social networks</li>
                  <li>Consumer data resellers</li>
                  <li>Service providers</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Professional or employment-related information</strong>{" "}
                <br />
                Current or past job history or performance evaluations.
              </td>
              <td>
                <ul style={{ listStyleType: "circle" }}>
                  <li>Advertising networks</li>
                  <li>Internet service providers</li>
                  <li>Data analytics providers</li>
                  <li>Government entities</li>
                  <li>Operating systems and platforms</li>
                  <li>Social networks</li>
                  <li>Consumer data resellers</li>
                  <li>Service providers</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <strong>
                  Inferences drawn from other personal information to create a
                  profile about a consumer
                </strong>{" "}
                <br />
                Profile reflecting a consumer's preferences, characteristics,
                psychological trends, predispositions, behavior, attitudes,
                abilities, and aptitudes.
              </td>
              <td>
                <ul style={{ listStyleType: "circle" }}>
                  <li>Advertising networks</li>
                  <li>Internet service providers</li>
                  <li>Data analytics providers</li>
                  <li>Government entities</li>
                  <li>Operating systems and platforms</li>
                  <li>Social networks</li>
                  <li>Consumer data resellers</li>
                  <li>Service providers</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        <p>
          <br />
          The categories of sources from which we collect personal information
          and our business and commercial purposes for using personal
          information are set forth above.
        </p>
        <p>
          <strong>“Sales” of Personal Information under the CCPA</strong>
        </p>
        <p>
          California residents have the right to opt out of the “sale” of their
          personal information. Under the CCPA, “sale” is defined broadly and
          includes the transfer of personal information by a business to a third
          party for valuable consideration (even if there is no exchange of
          money).
        </p>
        <p>
          Veridata Insights may “sell” personal information. The categories of
          personal information we have “sold” and the categories of third
          parties we have “sold” personal information to in the preceding twelve
          months are listed below:
        </p>

        <table>
          <tbody>
            <tr>
              <th>
                Category of Personal Information Sold by Veridata Insights
              </th>
              <th>Category of Third Parties Personal Information is Sold to</th>
            </tr>
            <tr>
              <td>
                <strong>Identifiers.</strong> <br />A real name, postal address,
                unique personal identifier, online identifier, Internet Protocol
                address, email address, Social Security number, or other similar
                identifiers.
              </td>
              <td>
                <ul style={{ listStyleType: "circle" }}>
                  <li>Advertising networks</li>
                  <li>Internet service providers</li>
                  <li>Data analytics providers</li>
                  <li>Government entities</li>
                  <li>Operating systems and platforms</li>
                  <li>Social networks</li>
                  <li>Consumer data resellers</li>
                  <li>Service providers</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <strong>
                  Personal information categories listed in the California
                  Customer Records statute (Cal. Civ. Code § 1798.80(e))
                </strong>{" "}
                <br />A name, signature, Social Security number, physical
                characteristics or description, address, telephone number,
                education, employment, employment history, medical information,
                or health insurance information. Personal information does not
                include publicly available information that is lawfully made
                available to the general public from federal, state, or local
                government records. Note: Some personal information included in
                this category may overlap with other categories.
              </td>
              <td>
                <ul style={{ listStyleType: "circle" }}>
                  <li>Advertising networks</li>
                  <li>Internet service providers</li>
                  <li>Data analytics providers</li>
                  <li>Government entities</li>
                  <li>Operating systems and platforms</li>
                  <li>Social networks</li>
                  <li>Consumer data resellers</li>
                  <li>Service providers</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <strong>
                  Protected classification characteristics under California or
                  federal law
                </strong>{" "}
                <br />
                Age (40 years or older), race, color, ancestry, national origin,
                citizenship, religion or creed, marital status, medical
                condition, physical or mental disability, sex (including gender,
                gender identity, gender expression, pregnancy or childbirth and
                related medical conditions), sexual orientation, veteran or
                military status, genetic information (including familial genetic
                information).
              </td>
              <td>
                <ul style={{ listStyleType: "circle" }}>
                  <li>Advertising networks</li>
                  <li>Internet service providers</li>
                  <li>Data analytics providers</li>
                  <li>Government entities</li>
                  <li>Operating systems and platforms</li>
                  <li>Social networks</li>
                  <li>Consumer data resellers</li>
                  <li>Service providers</li>{" "}
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Commercial information</strong> <br />
                Records of personal property, products or services purchased,
                obtained, or considered, or other purchasing or consuming
                histories or tendencies.
              </td>
              <td>
                <ul style={{ listStyleType: "circle" }}>
                  <li>Advertising networks</li>
                  <li>Internet service providers</li>
                  <li>Data analytics providers</li>
                  <li>Government entities</li>
                  <li>Operating systems and platforms</li>
                  <li>Social networks</li>
                  <li>Consumer data resellers</li>
                  <li>Service providers</li>{" "}
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Internet or other electronic network activity</strong>{" "}
                <br />
                Browsing history, search history, information on a consumer's
                interaction with an internet website, application, or
                advertisement.
              </td>
              <td>
                <ul style={{ listStyleType: "circle" }}>
                  <li>Advertising networks</li>
                  <li>Internet service providers</li>
                  <li>Data analytics providers</li>
                  <li>Government entities</li>
                  <li>Operating systems and platforms</li>
                  <li>Social networks</li>
                  <li>Consumer data resellers</li>
                  <li>Service providers</li>{" "}
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Geolocation data</strong> <br />
                Physical location or movements.
              </td>
              <td>
                <ul style={{ listStyleType: "circle" }}>
                  <li>Advertising networks</li>
                  <li>Internet service providers</li>
                  <li>Data analytics providers</li>
                  <li>Government entities</li>
                  <li>Operating systems and platforms</li>
                  <li>Social networks</li>
                  <li>Consumer data resellers</li>
                  <li>Service providers</li>{" "}
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Sensory data</strong> <br />
                Audio, electronic, visual, thermal, olfactory, or similar
                information.
              </td>
              <td>
                <ul style={{ listStyleType: "circle" }}>
                  <li>Advertising networks</li>
                  <li>Internet service providers</li>
                  <li>Data analytics providers</li>
                  <li>Government entities</li>
                  <li>Operating systems and platforms</li>
                  <li>Social networks</li>
                  <li>Consumer data resellers</li>
                  <li>Service providers</li>{" "}
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Professional or employment-related information</strong>{" "}
                <br />
                Current or past job history or performance evaluations.
              </td>
              <td>
                <ul style={{ listStyleType: "circle" }}>
                  <li>Advertising networks</li>
                  <li>Internet service providers</li>
                  <li>Data analytics providers</li>
                  <li>Government entities</li>
                  <li>Operating systems and platforms</li>
                  <li>Social networks</li>
                  <li>Consumer data resellers</li>
                  <li>Service providers</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <strong>
                  Inferences drawn from other personal information to create a
                  profile about a consumer
                </strong>{" "}
                <br />
                Profile reflecting a consumer's preferences, characteristics,
                psychological trends, predispositions, behavior, attitudes,
                abilities, and aptitudes.
              </td>
              <td>
                <ul style={{ listStyleType: "circle" }}>
                  <li>Advertising networks</li>
                  <li>Internet service providers</li>
                  <li>Data analytics providers</li>
                  <li>Government entities</li>
                  <li>Operating systems and platforms</li>
                  <li>Social networks</li>
                  <li>Consumer data resellers</li>
                  <li>Service providers</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        <p>
          <br />
          Our business and commercial purposes for “selling” personal
          information can be found in Section 3 “HOW WE USE YOUR INFORMATION”
          above. We do not have actual knowledge of any “sale” of personal
          information of minors under 18 years of age.
        </p>
        <p>
          <strong>Opt-out of “Sales.”</strong> California residents may opt-out
          of the “sale” of their personal information by contacting us at{" "}
          <a
            href="mailto:compliance@bizknowledge.com"
            target="_blank"
            rel="noopener noreferrer"
            className={Link}
          >
            compliance@bizknowledge.com
          </a>
          .
        </p>
        <p>
          <strong>Additional Privacy Rights of California Residents</strong>
        </p>
        <p>
          <strong>Non-Discrimination.</strong> California residents have the
          right not to receive discriminatory treatment by us for the exercise
          of their rights conferred by the CCPA.
        </p>
        <p>
          <strong>Authorized Agent.</strong> Only you, or someone legally
          authorized to act on your behalf, may make a verifiable consumer
          request related to your personal information. You may also make a
          verifiable consumer request on behalf of your minor child. To
          designate an authorized agent, contact{" "}
          <a href="mailto:compliance@bizknowledge.com" className={Link}>
            compliance@bizknowledge.com
          </a>
          .
        </p>
        <p>
          <strong>Verification.</strong> To protect your privacy, we will take
          the following steps to verify your identity before fulfilling your
          request. When you make a request, we will ask you to provide
          sufficient information that allows us to reasonably verify you are the
          person about whom we collected personal information or an authorized
          representative, which may include digital IP address collection, copy
          of driver’s license or other government ID.
        </p>
        <p>
          If you are a California resident and would like to exercise any of
          your rights under the CCPA, please contact us as set forth below. We
          will process such requests in accordance with applicable laws.
        </p>
        <p>
          <strong>Financial Incentives.</strong>
        </p>
        <ol type="1">
          <li>
            We may provide cash, point or sweepstake incentives as a thank you
            for participating in research panels.
          </li>
          <li>
            We will let you know the amount of the reward prior to when you
            agree to participate in each panel.
          </li>
          <li>
            A summary of your incentives for each survey you participate in will
            be available on your homepage at any time.
          </li>
          <li>
            You earn BizKnowledge points for each survey activity you validly
            complete. Every survey opportunity available to panelists will
            clearly outline the survey topic, the length of interview, interview
            methodology, the categories of personal information we will collect,
            and the number of points earned for validly completing the survey
            activity. If there are any differences in points you can earn by
            providing different types of personal information, we will also
            clearly outline this in our description of the survey opportunity.
          </li>
          <li>
            Each panelist has the ability to opt-in or opt-out of taking any
            particular survey. Panelists are not required to participate in any
            survey – participation is purely on a voluntary basis. To the extent
            a survey opportunity has different reward levels for providing
            different types of personal information, you may choose which types
            of information you provide and which level of reward you earn. If
            you have any questions about this, please contact us at:{" "}
            <a href="mailto:compliance@bizknowledge.com" className={Link}>
              compliance@bizknowledge.com
            </a>
            .
          </li>
          <li>
            You may withdraw from receiving financial awards at any time by
            either electing to not participate in a panel or terminating your
            account. (Please see our{" "}
            <a
              href={ROUTES.internal.termsOfService}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>{" "}
            for more information on how to terminate your account.) To close
            your account, click your name at the top right of the home page and
            select ‘My Profile’. On the right side of your profile page, click
            on ‘Account Settings’ and choose Delete Account.{" "}
            <strong>
              <u>
                When you close your account, all your profile information will
                be automatically deleted in our system and all reward balances
                will be forfeited.
              </u>
            </strong>{" "}
            Please note this cannot be undone once your account is closed. If
            you would like to terminate your account, we recommend redeeming
            points earned prior to terminating your account if the 10,000-point
            threshold to redeem points is met. If you meet the 10,000 point
            threshold, to redeem your accumulated points, please sign into to
            your BizKnowledge account and select ‘Redeem’ under Rewards on the
            home page. Choose the electronic reward you wish to redeem with and
            follow the steps to complete your order. First name and email
            address are required to redeem.
          </li>
          <li>
            We determine the number of points to award for each survey based on
            the audience complexity, length of interview and interview
            methodology.
          </li>
        </ol>

        <p>
          <strong>Accessibility.</strong> This Privacy Policy uses
          industry-standard technologies and was developed in line with the
          World Wide Web Consortium’s Web Content Accessibility Guidelines,
          version 2.1. If you wish to print this policy, please do so from your
          web browser or by saving the page as a PDF.
        </p>
        <p>
          <strong>California Shine the Light.</strong> The California “Shine the
          Light” law permits users who are California residents to request and
          obtain from us once a year, free of charge, a list of the third
          parties to whom we have disclosed their personal information (if any)
          for their direct marketing purposes in the prior calendar year, as
          well as the type of personal information disclosed to those parties.
        </p>

        <h2>
          10. SUPPLEMENTAL NOTICE FOR NEVADA RESIDENTS
          <a id="nevadares" />
        </h2>
        <p>
          If you are a resident of Nevada, you have the right to opt-out of the
          sale of certain personal information to third parties who intend to
          license or sell that personal information. You can exercise this right
          by contacting us at{" "}
          <a
            href="mailto:compliance@bizknowledge.com"
            target="_blank"
            rel="noopener noreferrer"
            className={Link}
          >
            compliance@bizknowledge.com
          </a>{" "}
          with the subject line “Nevada Do Not Sell Request” and providing us
          with your name and the email address associated with your account.
          Please note that we do not currently sell your personal information as
          sales are defined in Nevada Revised Statutes Chapter 603A.
        </p>

        <h2>
          11. CHILDREN’S INFORMATION
          <a id="children" />
        </h2>
        <p>
          The Services are not directed to children under 18(or other age as
          required by local law), and we do not knowingly collect personal
          information from children.
        </p>
        <p>
          Protecting children’s privacy online is very important to us. Although
          our Services are designed for a general audience, we take additional
          steps to obtain the parent’s verifiable consent before collecting any
          information from a child. We also limit personal information
          collection and sharing to only what is necessary to support the
          internal operations of our Services.
        </p>
        <p>
          If you are a parent or guardian and wish to review information
          collected from your child, or have that information modified or
          deleted, you may contact us as described below. If we become aware
          that a child has provided us with personal information in violation of
          applicable law, we will delete any personal information we have
          collected, unless we have a legal obligation to keep it, and terminate
          the child’s account and/or revert them to the underage experience, as
          applicable.
        </p>

        <h2>
          12. OTHER PROVISIONS
          <a id="otherprov" />
        </h2>
        <p>
          <strong>Third-Party Websites/Applications.</strong> The Services may
          contain links to other websites/applications and other
          websites/applications may reference or link to our Services. These
          third-party services are not controlled by us. We encourage our users
          to read the privacy policies of each website and application with
          which they interact. We do not endorse, screen or approve, and are not
          responsible for, the privacy practices or content of such other
          websites or applications. Providing personal information to
          third-party websites or applications is at your own risk.
        </p>
        <p>
          <strong>Supervisory Authority.</strong> If you are located in the
          European Economic Area, Switzerland, or the United Kingdom, you have
          the right to lodge a complaint with a supervisory authority if you
          believe our processing of your personal information violates
          applicable law.
        </p>
        <p>
          <strong>Changes to our Privacy Policy.</strong> We may revise this
          Privacy Policy from time to time in our sole discretion. If there are
          any material changes to this Privacy Policy, we will notify you as
          required by applicable law. You understand and agree that you will be
          deemed to have accepted the updated Privacy Policy if you continue to
          use our Services after the new Privacy Policy takes effect.
        </p>

        <h2>
          13. CONTACT US <a id="contactus" />
        </h2>
        <p>
          If you have any questions about our privacy practices or this Privacy
          Policy, or to exercise your rights as detailed in this Privacy Policy,
          please contact us at:
        </p>
        <p>
          <br />
          BizKnowledge, powered by Veridata Insights, LLC
          <br />
          Jami Pulley, CEO and Founder
          <br />
          101C North Greenville Ave, Suite 317
          <br />
          Allen, TX 75002
          <br />
          (888) 517-8783
          <br />
          <a
            href="mailto:compliance@bizknowledge.com"
            target="_blank"
            rel="noopener noreferrer"
            className={Link}
          >
            compliance@bizknowledge.com
          </a>
        </p>
      </Main>
    </Page>
  );
};

export default Privacy;
