export const ROUTES = {
  /* URLs explicitly redirecting to external website */
  external: {
    //Internal link
    business: "https://www.bizknowledge.com/business",
    //SampleNinja Integration
    login: "https://app.bizknowledge.com/app/login/1",
    registration: "https://surveys.sampletap.com/v2/fence/bkfence",
    //BK Social Media
    facebook: "https://www.facebook.com/profile.php?id=100083385366752",
    instagram: "https://www.instagram.com/biz.knowledge/",
    twitter: "https://twitter.com/BizKnowledge1",
    linkedIn: "https://www.linkedin.com/company/biz-knowledge/about/",
    //Corporate
    veridata: "https://www.veridatainsights.com",
    //Tango
    tango: {
      help: "https://help.tangocard.com",
    },
    registrationEntry: "https://api.bizknowledge.com/register/entry/1"
  },
  /* paths used for client-side routing within website */
  internal: {
    blog: "/blog",
    contact: "/contact",
    faqs: "/faqs",
    termsOfService: "/terms-of-service",
    privacyPolicy: "/privacy-policy",
    business: "/business",
    consumer: "/consumer",
    registration: "/register/:sourceId/:registrationId",
    error: "/error"
  },
};
