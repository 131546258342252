import { NavLink } from "react-router-dom";
import { ROUTES } from "../../../router/routes";

export const FAQS_DATA = [
  {
    id: 0,
    section: "join",
    question: "What is BizKnowledge?",
    answer: () => (
      <p>
        BizKnowledge is an online platform that pays you rewards for sharing
        your views. Our feedback opportunities vary and can include interviews,
        focus groups, or surveys. Because your views are so important to us, we
        believe in paying you fairly for your time.
      </p>
    ),
  },
  {
    id: 1,
    section: "join",
    question: "Who can join BizKnowledge?",
    answer: () => (
      <p>
        Consisting of intelligent and articulate members, BizKnowledge has paid
        feedback opportunities for people from all professions, industries, and
        sectors. We provide you with the opportunities best suited to you based
        on your profile. However, you must be 18 years or older to join.
        <br />
        <br />
        Our exclusive Elite Circle is geared toward elite business professionals
        where you can earn up to 10x the rewards by sharing your knowledge.
        Visit Elite Circle to find out if you qualify.
      </p>
    ),
  },
  {
    id: 2,
    section: "join",
    question: "How does BizKnowledge work?",
    answer: () => (
      <p>
        With BizKnowledge, earning rewards is simple.
        <br />
        <br />
        For every feedback opportunity you complete, we will reward you with
        points.
        <br />
        <br />
        These points can then be redeemed for electronic rewards of your choice,
        including gift cards or to donate to the World of Children, an
        organization that supports vulnerable children around the world.
      </p>
    ),
  },
  {
    id: 3,
    section: "join",
    question: "How does BizKnowledge use my views and opinions?",
    answer: () => (
      <p>
        Your views and opinions are of the utmost importance to the businesses
        and organizations that we work with.
        <br />
        <br />
        Providing actionable insights to key players, your anonymous feedback
        dictates the decisions made regarding their products, services, and
        strategy. Your views will influence the future and help shape the world
        around us.
      </p>
    ),
  },
  {
    id: 4,
    section: "join",
    question: "Are my views and opinions kept anonymous?",
    answer: () => (
      <p>
        Yes. When you share your views with BizKnowledge, your data is
        completely anonymous to our clients.
        <br />
        <br />
        Furthermore, we take your data privacy seriously. We will never release
        or sell your data without your expressed consent.
        <br />
        <br />
        For more information on your data rights, please view our{" "}
        <NavLink to={ROUTES.internal.privacyPolicy}>
          Privacy Policy
        </NavLink> and{" "}
        <NavLink to={ROUTES.internal.termsOfService}>Terms of Service</NavLink>
      </p>
    ),
  },
  {
    id: 5,
    section: "join",
    question: "How do I join?",
    answer: () => (
      <p>
        Joining BizKnowledge is easy. Click{" "}
        <a href={ROUTES.external.registrationEntry}>here</a> to get started and
        follow these simple steps.
        <br />
        <br />
        <ol>
          <li>
            <b>Create your BizKnowledge account </b>
            <br />
            To create your profile, we will ask for some details from you like
            your interests, demographic, and area of expertise. It only takes a
            few minutes, and you will be rewarded for each step of your profile
            you complete.
          </li>
          <li>
            <b>Take part in feedback opportunities to earn points </b>
            <br />
            Based on your profile, you can see in your member account all the
            feedback opportunities you might wish to participate in. Choose the
            opportunities you want and begin earning points. If you like, we can
            also email you invitations to opportunities you might be interested
            in.
          </li>
          <li>
            <b> Use your points to redeem rewards </b>
            <br />
            Using the points you earn from participating in our feedback
            opportunities, you can then redeem your points for electronic
            rewards of your choice, including gift cards or to donate to the
            World of Children, an organization that supports vulnerable children
            around the world.
          </li>
        </ol>
      </p>
    ),
  },
  {
    id: 6,
    section: "join",
    question: "What happens after I join?",
    answer: () => (
      <p>
        After you join BizKnowledge and log in, you will be shown all the paid
        feedback opportunities available to you. They are under ‘Surveys’ in the
        menu on the lefthand side of the screen.
        <br />
        <br />
        Simply choose the opportunities you want to participate in and earn
        points. We will also email you invitations to feedback opportunities
        that might be of interest.
        <br />
        <br />
        These emails contain the information you need to participate such as
        topic, the length of the survey, and the points you earn for taking
        part.
      </p>
    ),
  },
  {
    id: 7,
    section: "join",
    question: "I’m unable to join BizKnowledge. Can you help?",
    answer: () => (
      <p>
        Absolutely. If you are having trouble signing up for BizKnowledge,
        please <NavLink to={ROUTES.internal.contact}>Contact Us</NavLink>.
        <br />
        <br />
        Remember, only one (1) BizKnowledge account per person and two (2) per
        household.
      </p>
    ),
  },
  {
    id: 8,
    section: "join",
    question:
      "I joined but I haven’t received my account activation email. What should I do?",
    answer: () => (
      <p>
        After you register and join BizKnowledge, you will receive an account
        activation email sent to the email address that was provided. Remember
        to check your spam folder.
        <br />
        <br />
        If you have not received your activation email after 30 minutes, please{" "}
        <NavLink to="/contact">Contact Us</NavLink>.
      </p>
    ),
  },
  {
    id: 9,
    section: "membership",
    question: "How do I access my BizKnowledge account?",
    answer: () => (
      <p>
        To access your BizKnowledge account, select ‘Sign In’ at the top right
        of <NavLink to="/">www.bizknowledge.com</NavLink>, or click{" "}
        <a href={ROUTES.external.registrationEntry}>here</a>
        <br />
        <br />
        Once you are logged into your account, you can select available surveys
        and feedback opportunities to earn and redeem points.
        <br />
        <br />
        Each time you log into your account, you will have the chance to update
        your profile and earn bonus points for each question. The more you
        share, the more relevant opportunities you will receive.
      </p>
    ),
  },
  {
    id: 10,
    section: "membership",
    question: "How do I update my profile information?",
    answer: () => (
      <p>
        It is important that you keep your profile updated to ensure that you
        are matched with current, relevant, and rewarding feedback
        opportunities. Whether you have recently changed careers, relocated, or
        got married, please let us know.
        <br />
        <br />
        Once you are logged into your member account, select ‘Profile’ from the
        menu on the lefthand side of the screen. We will ask you questions each
        day to update more of your profile, and we will reward you for those
        too.
      </p>
    ),
  },
  {
    id: 11,
    section: "membership",
    question: "Can I complete my profile in one day?",
    answer: () => (
      <p>
        No need to. We will ask you questions each day so to complete more of
        your profile, and we will reward you for those too.
        <br />
        <br />
        Once you are logged into your member account, select ‘Profile’ from the
        menu on the lefthand side of the screen.
      </p>
    ),
  },
  {
    id: 12,
    section: "membership",
    question: "How do I view my collected data?",
    answer: () => (
      <p>
        Once you are logged into your member account, select ‘My Account’ from
        the menu on the lefthand side of the screen. Then select ‘View My Data.’
      </p>
    ),
  },
  {
    id: 13,
    section: "membership",
    question: "How do I see how many surveys I have taken?",
    answer: () => (
      <p>
        Once you are logged into your member account, select ‘My Account’ from
        the menu on the lefthand side of the screen. Then you will see your
        ‘Completed Surveys’ displayed.
      </p>
    ),
  },
  {
    id: 14,
    section: "membership",
    question: "How do I see how long I have been a member?",
    answer: () => (
      <p>
        Once you are logged into your member account, select ‘My Account’ from
        the menu on the lefthand side of the screen. Then you will see your
        ‘Days as a Member’ displayed.
      </p>
    ),
  },
  {
    id: 15,
    section: "membership",
    question: "I have forgotten my password. What should I do?",
    answer: () => (
      <p>
        Click ‘Sign In’ at the top right of{" "}
        <NavLink to="/"> www.bizknowledge.com</NavLink>. Enter the email address
        you provided when creating your BizKnowledge account and click ‘Reset
        Password.’ Once you receive our email, you can click the link to reset
        your password.
      </p>
    ),
  },
  {
    id: 16,
    section: "membership",
    question: "How do I close my account?",
    answer: () => (
      <p>
        We are sorry to see you leave. If there is anything we can do to keep
        you as a BizKnowledge member, please{" "}
        <NavLink to={ROUTES.internal.contact}>Contact Us</NavLink>
        <br />
        <br />
        To close your account, select ‘My Account’ from the menu on the lefthand
        side of the screen. Then click ‘Close Account’ in the bottom left of the
        screen. When you close your account, all your profile information will
        be automatically deleted from our system and all reward balances will be
        forfeited. Please note that this cannot be undone once your account is
        closed.
      </p>
    ),
  },
  {
    id: 17,
    section: "surveysFeedbackOpportunities",
    question:
      "How do I begin taking part in surveys and feedback opportunities?",
    answer: () => (
      <p>
        Once logged in, you will be shown all the paid feedback opportunities
        available to you. They are under ‘Surveys’ in the menu on the lefthand
        side of the screen.
        <br />
        <br />
        Log into your account and you will be shown all the surveys and
        opportunities available to you. Simply select the ones you would like to
        participate in and begin the survey.
        <br />
        <br />
        We will also email you invitations to surveys and opportunities that
        might be of interest. You will receive email invitations from{" "}
        <a href="mailto:team@bizknowledge.com">team@bizknowledge.com</a> to
        participate in feedback opportunities that are pre-selected to match
        your interests, demographic, or area of expertise.
        <br />
        <br />
        It is important that you complete your profile and keep your profile
        updated to ensure we provide you with relevant, interesting, and
        rewarding surveys. We will ask you questions each day to complete more
        of your profile, and you will be rewarded for those too.
        <br />
        <br />
        Did you recently move, change jobs, discover a new interest, or have a
        baby? Be sure to update your profile for maximum earning potential.
      </p>
    ),
  },
  {
    id: 18,
    section: "surveysFeedbackOpportunities",
    question: "How do I see my survey participation history?",
    answer: () => (
      <p>
        Once you are logged into your member account, select ‘Activity History’
        from the menu on the lefthand side of the screen.
      </p>
    ),
  },
  {
    id: 19,
    section: "surveysFeedbackOpportunities",
    question: "Why haven’t I received any feedback opportunity invitations?",
    answer: () => (
      <p>
        The feedback opportunity invitations we send to your email address might
        be going to your spam folder or they might be getting blocked. Please
        check your email settings and add BizKnowledge to your contacts.
        <br />
        <br />
        You can also join feedback opportunities from your member account by
        selecting ‘Surveys’ from the menu on the lefthand side of the screen.
      </p>
    ),
  },
  {
    id: 20,
    section: "surveysFeedbackOpportunities",
    question:
      "How long will it take to complete each survey or feedback opportunity?",
    answer: () => (
      <p>
        Each survey or feedback opportunity is unique, but our surveys take
        between 15-20 minutes to complete.
        <br />
        <br />
        The estimated survey length is provided in each survey invitation email
        and provided when you join through your member account by selecting
        ‘Surveys’ from the menu on the lefthand side of the screen.
        <br />
        <br />
        Our other feedback opportunities, like comprehensive interviews, can
        take longer than this. But in your account, and in the email invitation,
        we provide you with the length of time required. And of course, we
        reward you fairly for your time.
      </p>
    ),
  },
  {
    id: 21,
    section: "surveysFeedbackOpportunities",
    question: "How much will I earn for each feedback opportunity?",
    answer: () => (
      <p>
        Earnings from surveys vary widely, typically ranging from $0.50 to $5
        per survey, with longer and more specialized and comprehensive surveys
        paying up to $100 or more.
        <br />
        <br />
        The number of reward points offered will vary depending on the time and
        nature of the feedback opportunity. For example, a brief survey will
        offer fewer reward points than a longer in-depth interview.
      </p>
    ),
  },
  {
    id: 22,
    section: "surveysFeedbackOpportunities",
    question: "Why didn’t I qualify for a survey?",
    answer: () => (
      <p>
        We send surveys and feedback opportunities that are relevant to you
        based on your profile information.
        <br />
        <br />
        For example, if we have a survey about homeownership and you have
        indicated that you are not a homeowner, this survey would not be
        relevant for you to qualify.
        <br />
        <br />
        To ensure you can qualify for surveys, please complete, or update your
        profile so we have updated and accurate information about you.
      </p>
    ),
  },
  {
    id: 23,
    section: "surveysFeedbackOpportunities",
    question:
      "I’m experiencing technical difficulties with a survey. What can I do?",
    answer: () => (
      <p>
        Please try to access your survey in a different internet browser.
        <br />
        <br />
        If that does not resolve the problem, please{" "}
        <NavLink to={ROUTES.internal.contact}>Contact Us</NavLink> and we will
        investigate the issue to ensure your survey experience is as smooth as
        possible.
      </p>
    ),
  },
  {
    id: 24,
    section: "surveysFeedbackOpportunities",
    question:
      "I have completed a survey. When will my account be credited with points?",
    answer: () => (
      <p>
        Once you have successfully completed a survey, your account will be
        credited with BizKnowledge points instantly. More complex feedback
        activities such as focus groups may take up to 2-3 weeks.
        <br />
        <br />
        If you have any questions regarding the status of your points, please{" "}
        <NavLink to={ROUTES.internal.contact}>Contact Us</NavLink>
      </p>
    ),
  },
  {
    id: 25,
    section: "rewards",
    question: "How do I earn BizKnowledge points?",
    answer: () => (
      <p>
        BizKnowledge points are earned for each survey or feedback opportunity
        you participate in. The number of points you earn depends on the topic
        and the length of the survey, focus group, or interview you complete. We
        reward you fairly for your time, so for example, you will earn more
        points for a comprehensive interview than you will for a quick opinion
        poll.
        <br />
        <br />
        Accumulated points can be exchanged for various electronic rewards
        including gift cards, donations, and more.
      </p>
    ),
  },
  {
    id: 26,
    section: "rewards",
    question: "How do I redeem my points for a reward?",
    answer: () => (
      <p>
        To redeem your accumulated points, Sign In to your BizKnowledge account
        and select ‘Rewards’ from the menu on the lefthand side of the screen.
        The minimum redemption amount is 10,000 points.
        <br />
        <br />
        Choose the electronic reward you wish to redeem for and follow the steps
        to complete your order. After completing your order, you will receive
        your electronic reward within minutes.
      </p>
    ),
  },
  {
    id: 27,
    section: "rewards",
    question: "How do I check my reward point balance?",
    answer: () => (
      <p>
        You can view your current points balance at any time. Simply Sign In to
        your BizKnowledge account and your points balance is displayed in the
        top righthand corner of the screen. Remember that when you use points to
        redeem rewards, your reward balance will reflect this.
      </p>
    ),
  },
  {
    id: 28,
    section: "rewards",
    question: "How do I see how many lifetime reward points I have accrued?",
    answer: () => (
      <p>
        Once you are logged into your member account, select ‘My Account' from
        the menu on the lefthand side of the screen. Then you will see your
        ‘Lifetime Reward Points’ displayed.
      </p>
    ),
  },
  {
    id: 29,
    section: "rewards",
    question: "How do I see my points redemption history?",
    answer: () => (
      <p>
        Once you are logged into your member account, select ‘Redemptions’ from
        the menu on the lefthand side of the screen.
      </p>
    ),
  },
  {
    id: 30,
    section: "rewards",
    question:
      "I redeemed for an electronic reward and have not received it yet. What should I do?",
    answer: () => (
      <p>
        If you have any problems or questions regarding your electronic reward,
        please <NavLink to={ROUTES.internal.contact}>Contact Us</NavLink>
      </p>
    ),
  },
];
