import { Button } from "../../../atoms";
import { Header, Heading, HeroImage1, HeroImage2 } from "./Hero.styles";
import { useNavigate } from "react-router-dom";

const Hero = ({
  leftImage,
  rightImage,
  header1,
  header2,
  header3,
  cta,
  customHero,
}) => {
  const navigate = useNavigate();
  const navigation = cta?.external
    ? () => (window.location.href = cta.action)
    : () => navigate(cta.action);

  return customHero ? (
    customHero
  ) : (
    <Header>
      <img
        src={leftImage}
        alt="hero-img-#1"
        className={HeroImage1}
        loading="eager"
      />
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Heading>{header1}</Heading>
        <Heading>{header2}</Heading>
        <Heading>{header3}</Heading>
        <Button onClick={navigation}>{cta.label}</Button>
      </section>
      <img
        src={rightImage}
        alt="hero-img-#2"
        className={HeroImage2}
        loading="eager"
      />
    </Header>
  );
};

export default Hero;
