import { css } from "@linaria/core";
import { styled } from "@linaria/atomic";
import { colors } from "../../../theme";

export const Header3 = css`
  font-weight: 700;
  font-size: 20px;
`;

export const Container = css`
  width: 100%;
  padding: 4vh 4vw;
  display: flex;
  flex-direction: column;
  gap: 32px;
  @media screen and (min-width: 800px) {
    padding: 4vh 16vw;
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 0;
  min-height: 50vh;
`;

export const QuestionItem = styled.article`
  display: flex;
  flex-direction: column;
  padding: 24px;
  cursor: pointer;
  gap: 16px;
  border-radius: 9px;
  user-select: none;

  &:hover {
    background-color: ${colors.action.hover};
  }
`;

export const Toolbar = css`
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
`;

export const FilterItem = styled.button`
  font-family: Lato, sans-serif;
  padding: 8px 16px;
  border-radius: 100px;
  border: 0;
  outline: 0;
  font-size: 1.15rem;
  font-weight: bold;
  cursor: pointer;
  width: fit-content;
  background-color: transparent;
  color: ${(props) => (props.active ? colors.primary : colors.textSecondary)};

  &:hover {
    color: ${colors.secondary};
  }
`;

export const QuestionBody = css`
  cursor: default;
`;
