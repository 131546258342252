import { LandingPage } from "../../templates";
import {
  Rewards,
  TeamworkHands,
  PinkProfessionalWoman,
  CasualManGreenTrimmed,
  ProfessionalWomanOlder,
  StickyNotesMeeting,
  YoungManExclaiming,
  WomanPurpleBackground,
  ProfessionalManAtDesk,
} from "../../../assets";
import { Paragraph } from "../../atoms";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../../router/routes";

const data = {
  hero: {
    leftImage: CasualManGreenTrimmed,
    rightImage: TeamworkHands,
    header1: "It Pays To Give",
    header2: "Your Opinion",
    cta: {
      label: "Join Now",
      action: ROUTES.external.registrationEntry,
      external: true
    },
  },
  firstSection: {
    header: "Share your views, get paid, shape the future!",
    content: (
      <>
        <Paragraph>
          BizKnowledge is an online platform that pays you with rewards for
          sharing your views.
        </Paragraph>
        <Paragraph>
          We have paid feedback opportunities for EVERYONE, and we reward people
          from all walks of life for giving their opinions.
        </Paragraph>
      </>
    ),
    image: ProfessionalWomanOlder,
    imageAlt: "professional-older-woman",
    orientation: "right",
    cta: {
      label: "Start Earning",
      action: ROUTES.external.registrationEntry,
      external: true
    },
  },
  secondSection: {
    header: "Surveys for Rewards",
    content: (
      <>
        <Paragraph>
          Are you looking to earn from home in as little as 10 minutes?
        </Paragraph>
        <Paragraph>
          Do you want to try new products for free before they hit the market?
        </Paragraph>
        <Paragraph>
          Do you want to help shape the products of the future? If you answered
          ‘yes’, then BizKnowledge is for you!
        </Paragraph>
      </>
    ),
    cta: {
      label: "Join Now",
      action: ROUTES.external.registrationEntry,
      external: true
    },
    image: YoungManExclaiming,
    imageAlt: "young-main-exclaiming-flipped",
    orientation: "left",
  },
  thirdSection: {
    header: "Working Professionals",
    content: (
      <>
        <Paragraph>
          You might not realize it, but your everyday knowledge from just doing
          your job is valuable!
        </Paragraph>
        <Paragraph>
          Our exclusive <b style={{ color: "#366eb8" }}>Elite Circle</b> is
          geared toward working professionals, where you can earn up to 10x the
          reward points by sharing your workplace knowledge!
        </Paragraph>
      </>
    ),
    cta: {
      label: "Learn More",
      action: ROUTES.internal.business,
      stayInTab: true
    },
    image: StickyNotesMeeting,
    imageAlt: "sticky-notes-meeting",
    orientation: "right",
  },
  howItWorks: {
    subheader: "With BizKnowledge, reaping your rewards is a breeze!",
    content: [
      {
        id: "signUp",
        label: "Step 1 - Sign Up",
        content:
          "Create your BizKnowledge account in 2 minutes, and you’ll earn points for each step of your profile you complete.",
        image: WomanPurpleBackground,
        imageAlt: "signup",
        width: "100%",
      },
      {
        id: "earn",
        label: "Step 2 - Earn",
        content:
          "Take surveys to earn points. Choose the surveys you want to do, and we can email you invitations to surveys too.",
        image: ProfessionalManAtDesk,
        imageAlt: "earn",
        width: "100%",
      },
      {
        id: "redeem",
        label: "Step 3 - Redeem",
        content:
          "Redeem your points for gift cards including Amazon and Visa or donate to the World of Children charity.",
        image: Rewards,
        alt: "rewards",
        width: 400,
      },
    ],
    cta: {
      label: "Start Earning",
      action: ROUTES.external.registrationEntry,
      external: true
    },
  },
  dataPrivacy: {
    header: "Your data is safe with us!",
    content: (
      <>
        <Paragraph>
          We take your data privacy seriously! We’ll never release or sell your
          data without your expressed consent. And when you take an online
          survey with BizKnowledge, your data is completely anonymous to our
          clients.{" "}
        </Paragraph>
        <Paragraph>
          For more information on your data rights, please view our{" "}
          <NavLink to={ROUTES.internal.privacyPolicy}>Privacy Policy</NavLink>{" "}
          and{" "}
          <NavLink to={ROUTES.internal.termsOfService}>
            Terms of Service
          </NavLink>
        </Paragraph>
      </>
    ),
    image: PinkProfessionalWoman,
    imageAlt: "professional-women-in-pink",
    orientation: "left",
  },
};

const General = () => {
  return <LandingPage title="Home" data={data} />;
};

export default General;
