import { Children } from "react";
import { styled } from "@linaria/atomic";

const StackRoot = styled.div`
  display: flex;
  align-items: ${(props) => props.align};
  flex-direction: ${(props) => props.direction};
  gap: ${(props) => `${props.spacing}rem`};
`;

const Stack = ({
  children,
  align = "center",
  direction,
  spacing = 1,
  style,
}) => {
  return (
    <StackRoot
      style={style}
      align={align}
      spacing={spacing}
      direction={direction}
    >
      {Children.toArray(children).map((child, index) => (
        <div key={index} style={{ width: "100%" }}>
          {child}
        </div>
      ))}
    </StackRoot>
  );
};

export default Stack;
