import { Hero, HowItWorks } from "../organisms/Sections";
import { Page } from "../atoms";
import Block from "../molecules/Block";

const LandingPage = ({ title = "BizKnowledge", data }) => {
  const {
    hero,
    howItWorks,
    dataPrivacy,
    firstSection,
    secondSection,
    thirdSection,
  } = data;

  return (
    <Page title={title}>
      {hero && <Hero {...hero} />}
      {firstSection && (
        <Block
          header={firstSection.header}
          content={firstSection.content}
          image={firstSection.image}
          imageAlt={firstSection.imageAlt}
          orientation={firstSection.orientation}
          cta={firstSection.cta}
        />
      )}
      {secondSection && (
        <Block
          header={secondSection.header}
          content={secondSection.content}
          image={secondSection.image}
          imageAlt={secondSection.imageAlt}
          orientation={secondSection.orientation}
          cta={secondSection.cta}
        />
      )}
      {thirdSection && (
        <Block
          header={thirdSection.header}
          content={thirdSection.content}
          image={thirdSection.image}
          imageAlt={thirdSection.imageAlt}
          orientation={thirdSection.orientation}
          cta={thirdSection.cta}
        />
      )}
      {howItWorks && <HowItWorks {...howItWorks} />}
      {dataPrivacy && (
        <Block
          header={dataPrivacy.header}
          content={dataPrivacy.content}
          image={dataPrivacy.image}
          imageAlt={dataPrivacy.imageAlt}
          orientation={dataPrivacy.orientation}
        />
      )}
    </Page>
  );
};

export default LandingPage;
