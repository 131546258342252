import { css } from "@linaria/core";
import { styled } from "@linaria/atomic";
import { mediaQuery, colors } from "../../../theme";

export const SignInButton = css`
  color: ${colors.white};
  font-family: Lato, sans-serif;
  background-color: ${colors.primary};
  padding: 8px 16px;
  cursor: pointer;
  border: 0;
  border-radius: 9px;
  font-size: 1rem;
  font-weight: 700;
  width: max-content;
  display: inline-flex;
  text-transform: uppercase;
  letter-spacing: 0.25px;
  transition: background-color 200ms;

  &:hover {
    background-color: ${colors.secondary};
  }

  ${mediaQuery.desktop} {
    padding: 10px 20px;
  }
`;

export const Links = css`
  display: none;
  padding: 0;
  margin: 0;
  list-style: none;
  align-items: center;

  ${mediaQuery.desktop} {
    display: flex;
    gap: 0.5rem;
    flex-direction: row;
  }
`;

export const Link = css`
  display: block;
  font-size: 1rem;
  font-weight: 700;
  padding: 10px 16px;
  color: ${colors.textSecondary};
  border-radius: 100px;
  margin: 0 8px;
  transition: background-color 200ms;
  &:hover {
    color: ${colors.primary};
  }
  ${mediaQuery.desktop} {
    padding: 10px 16px;
    font-size: 1.15rem;
  }
`;

export const HeaderRoot = styled.header`
  width: 100%;
  z-index: 1200;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  transition: padding 200ms;
  padding: 0 24px;

  ${mediaQuery.tablet} {
    padding: 8px 64px;
  }

  ${mediaQuery.laptop} {
    padding: 16px 96px;
  }
`;

export const Logo = styled.img`
  height: 50px;
  margin: 16px auto;
  display: block;
  transition: height 100ms;
  ${mediaQuery.tablet} {
    margin: 0;
    height: 65px;
    display: inline-block;
    vertical-align: middle;
  }
`;
