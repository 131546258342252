import "./snackbar.css";

const Snackbar = (props) => {
  return (
    <div
      className="snackbar"
      id={props?.showSnackBar?.show ? "show" : "hide"}
      style={{
        backgroundColor:
          props?.showSnackBar?.type === "success" ? "#00F593" : "#FF0033",
        color: props?.showSnackBar?.type === "success" ? "black" : "white",
      }}
      onClick={() => props.handleClose()}
    >
      <div className="symbol">
        {props?.showSnackBar?.type === "success" ? (
          <h1>&#x2713;</h1>
        ) : (
          <h1>&#x2613;</h1>
        )}
      </div>
      <div className="message">{props?.showSnackBar?.message}</div>
    </div>
  );
};

export default Snackbar;
