import { useState } from "react";
import Banner from "../../molecules/Banner";
import { FAQS_DATA } from "./Data";
import { FiPlus, FiX } from "react-icons/fi";
import {
  Container,
  FilterItem,
  Header3,
  QuestionBody,
  QuestionItem,
  Section,
  Toolbar,
} from "./FAQs.styles";
import { Page } from "../../atoms";

const Question = ({ id, question, answer }) => {
  const [show, setShow] = useState(false);

  return (
    <QuestionItem
      key={id}
      active={show}
      onClick={() => setShow((prev) => !prev)}
    >
      <header
        style={{
          display: "flex",
          alignItems: "center",
          gap: 12,
        }}
      >
        <i
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {!show ? <FiPlus fontSize={24} /> : <FiX fontSize={24} />}
        </i>
        <h2 className={Header3}>{question}</h2>
      </header>
      {show && answer && <div className={QuestionBody}>{answer()}</div>}
    </QuestionItem>
  );
};

const QuestionTab = ({ id, value, onCurrentTab, label }) => {
  return (
    <FilterItem key={id} active={id === value} onClick={() => onCurrentTab(id)}>
      <span>{label}</span>
    </FilterItem>
  );
};

const FAQs = () => {
  const [currentTab, setCurrentTab] = useState("all");

  const handleChangeTab = (id) => {
    setCurrentTab(id);
  };

  const SECTIONS = [
    { id: "all", label: "All" },
    { id: "join", label: "Join" },
    { id: "membership", label: "Membership" },
    {
      id: "surveysFeedbackOpportunities",
      label: "Surveys & Feedback Opportunities",
    },
    {
      id: "rewards",
      label: "Rewards",
    },
  ];

  return (
    <Page title="FAQs">
      <Banner
        title="Frequently Asked Questions"
        subtitle="Have questions? We're here to help!"
      />
      <main className={Container}>
        <nav className={Toolbar}>
          {SECTIONS?.map(({ id, label }) => (
            <QuestionTab
              id={id}
              key={id}
              label={label}
              value={currentTab}
              onCurrentTab={handleChangeTab}
            />
          ))}
        </nav>
        <Section>
          {FAQS_DATA.filter(({ section }) =>
            currentTab !== "all" ? section === currentTab : section
          ).map(({ id, question, answer }) => (
            <Question key={id} id={id} question={question} answer={answer} />
          ))}
        </Section>
      </main>
    </Page>
  );
};

export default FAQs;
