import { createBrowserRouter } from "react-router-dom";
import Layout from "../components/organisms/Layout";
import {
  Blog,
  Business,
  Consumer,
  Contact,
  FAQs,
  General,
  PageNotFound,
  Privacy,
  Terms,
} from "../components";
import { ROUTES } from "./routes";
import RegistrationError from "../components/pages/RegistrationError";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { index: true, element: <General /> },
      { path: ROUTES.internal.faqs, element: <FAQs /> },
      { path: ROUTES.internal.contact, element: <Contact /> },
      { path: ROUTES.internal.blog, element: <Blog /> },
      { path: ROUTES.internal.privacyPolicy, element: <Privacy /> },
      { path: ROUTES.internal.termsOfService, element: <Terms /> },
      { path: ROUTES.internal.business, element: <Business /> },
      { path: ROUTES.internal.consumer, element: <Consumer /> },
    ],
  },
  {
    path: ROUTES.internal.registration,
    lazy: () => import("../components/pages/Registration/Registration"),
  },
  { path: "*", element: <PageNotFound /> },
  { path: "error/registration", element: <RegistrationError /> },
]);

export default router;
