import { styled } from "@linaria/atomic";
import { mediaQuery } from "../../theme";

const Paragraph = styled.p`
  font-size: 1.15rem;
  line-height: 1.5;
  text-align: ${(props) => (props.align === "center" ? "center" : "left")};

  ${mediaQuery.tablet} {
    font-size: 1.25rem;
    text-align: left;
  }
`;

export default Paragraph;
