import { styled } from "@linaria/atomic";
import { mediaQuery, colors } from "../../../../theme";
import { css } from "@linaria/core";

export const Header = styled.header`
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  gap: 64px;
  transition: all 0.1s ease;
    padding: 24px;


    ${mediaQuery.tablet} {
    height: calc(100vh - 80px);
      padding: 64px;

  }

  ${mediaQuery.laptop} {
    padding: 96px;
  }

  ${mediaQuery.desktop} {
    padding: 64px 128px;
  }
`;

export const Heading = styled.h1`
  font-weight: 700;
  font-size: clamp(3rem, 5vw + 1rem, 5rem);
  margin-bottom: 1.5rem;
  text-align: center;
  transition: font-size 200ms;
  color: ${colors.secondary};
`;

export const HeroImage1 = css`
  border-radius: 50%;
  width: 0;
  height: auto;
  position: absolute;
  top: 24px;

  ${mediaQuery.tablet} {
    left: 64px;
    width: clamp(15%, 18%, 22%);
  }

  ${mediaQuery.laptop} {
    left: 96px;
  }
`;

export const HeroImage2 = css`
  border-radius: 50%;
  width: 0;
  height: auto;
  position: absolute;
  bottom: 24px;

  ${mediaQuery.tablet} {
    right: 64px;
    width: clamp(15%, 18%, 22%);
  }

  ${mediaQuery.laptop} {
    right: 96px;
  }
`;
