import { styled } from "@linaria/atomic";
import { colors, mediaQuery } from "../../theme";

const ButtonRoot = styled.button`
  display: flex;
  justify-content: center;
  appearance: none;
  border: 0;
  padding: 24px 36px;
  font-family: Lato, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none !important;
  border-radius: 15px;
  color: ${colors.white};
  background-color: ${colors.primary};
  cursor: pointer;
  transition: width 200ms, font-size 200ms, background-color 200ms;
  font-size: 32px;
  width: 100%;
  z-index: 98;

  ${mediaQuery.desktop} {
    display: inline-flex;
    width: fit-content;
    padding: 20px 36px;
  }

  &:hover {
    background-color: ${colors.secondary};
  }
`;

const Button = ({ children, onClick, as = "button", ...other }) => {
  return (
    <ButtonRoot onClick={onClick} as={as} {...other}>
      {children}
    </ButtonRoot>
  );
};

export default Button;
