import { LandingPage } from "../../templates";
import {
  Rewards,
  PinkProfessionalWoman,
  CasualWomanInBlue,
  PurpleBackgroundMan,
  YoungManExclaiming,
  CasualManGreenTrimmed,
  WomanPurpleBackground,
  ProfessionalWomanOlder,
} from "../../../assets";
import { Paragraph } from "../../atoms";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../../router/routes";

const data = {
  hero: {
    leftImage: CasualWomanInBlue,
    rightImage: PurpleBackgroundMan,
    header1: "Take Surveys",
    header2: "Get Paid",
    header3: "Shape the Future",
    cta: {
      label: "Join Now",
      action: ROUTES.external.registrationEntry,
      external: true
    },
  },
  firstSection: {
    header:
      "Get paid to take online surveys from anywhere in as little as 10 minutes",
    content: (
      <>
        <Paragraph>
          Are you looking to earn from home in as little as 10 minutes?
        </Paragraph>
        <Paragraph>
          Do you want to try new products for free before they hit the market?
        </Paragraph>
        <Paragraph>
          Do you want to help shape the products of the future?
        </Paragraph>
        <Paragraph>
          If you answered ‘yes’, then BizKnowledge is for you!
        </Paragraph>
      </>
    ),
    image: PinkProfessionalWoman,
    imageAlt: "pink-professional-woman",
    orientation: "right",
    cta: {
      label: "Start Earning",
      action: ROUTES.external.registrationEntry,
      external: true
    },
  },
  secondSection: {
    header: "BizKnowledge pays for your opinion",
    content: (
      <>
        <Paragraph>
          BizKnowledge is an online platform that lets you take surveys from
          anywhere, anytime!{" "}
        </Paragraph>
        <Paragraph>
          You can choose the surveys you want to do, and your opinions are so
          valuable to us that we’ll pay you for them!{" "}
        </Paragraph>
        <Paragraph>Sounds good, right?</Paragraph>
      </>
    ),
    cta: {
      label: "Join Now",
      action: ROUTES.external.registrationEntry,
      external: true
    },
    image: YoungManExclaiming,
    imageAlt: "young-man-exclaiming-flipped",
    orientation: "left",
  },
  howItWorks: {
    subheader:
      "With BizKnowledge, taking paid surveys from home is a piece of cake!",
    content: [
      {
        id: "signUp",
        label: "Step 1 - Sign Up",
        content:
          "Create your BizKnowledge account in 2 minutes, and you’ll earn points for each step of your profile you complete. How cool is that?",
        image: CasualManGreenTrimmed,
        imageAlt: "signup",
        width: "100%",
      },
      {
        id: "earn",
        label: "Step 2 - Earn",
        content:
          "Take surveys to earn points. Simply choose the surveys you want to do and if you want, we’ll email you invitations to surveys too.",

        image: ProfessionalWomanOlder,
        imageAlt: "earn",
        width: "100%",
      },
      {
        id: "redeem",
        label: "Step 3 - Redeem",
        content:
          "This is the best part! Redeem your points for gift cards including Amazon and Visa or to donate to the World of Children charity.",
        image: Rewards,
        alt: "rewards",
        width: 400,
      },
    ],
    cta: {
      label: "Start Earning",
      action: ROUTES.external.registrationEntry,
      external: true
    },
  },
  dataPrivacy: {
    header: "Your data is safe with us!",
    content: (
      <>
        <Paragraph>
          We take your data privacy seriously! We’ll never release or sell your
          data without your expressed consent. And, when you take a survey, your
          data is completely anonymous to our clients.
        </Paragraph>
        <Paragraph>
          For more information on your data rights, please view our{" "}
          <NavLink to={ROUTES.internal.privacyPolicy}>Privacy Policy</NavLink>{" "}
          and{" "}
          <NavLink to={ROUTES.internal.termsOfService}>
            Terms of Service
          </NavLink>
        </Paragraph>
      </>
    ),
    image: WomanPurpleBackground,
    imageAlt: "TBD",
    orientation: "left",
  },
};

const Consumer = () => {
  return <LandingPage title="Consumer" data={data} />;
};

export default Consumer;